<template>
  <div class="app-loader" :style="cssProps">
    <div />
    <div />
    <div />
    <div />
  </div>
</template>

<script>
export default {
  name: 'AppLoader',
  props: {
    borderWidth: { type: Number, default: 4 },
    inverse: { type: Boolean, default: false },
    margin: { type: Number, default: 8 },
    size: { type: Number, default: 35 },
  },
  computed: {
    cssProps() {
      return {
        '--app-loader-border-width': this.borderWidth + 'px',
        '--app-loader-color': this.inverse ? '#ffffff' : '#363636',
        '--app-loader-margin': this.margin + 'px',
        '--app-loader-size': this.size + 'px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.app-loader {
  display: inline-block;
  position: relative;
  width: var(--app-loader-size);
  height: var(--app-loader-size);

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--app-loader-size);
    height: var(--app-loader-size);
    margin: var(--app-loader-margin);
    border-width: var(--app-loader-border-width);
    border-style: solid;
    border-color: var(--app-loader-color) transparent transparent transparent;
    border-radius: 50%;
    animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
