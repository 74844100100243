<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
    <defs>
      <path id="ov2ma" d="M337.365 690L332 695.8l5.331 5.765"></path>
      <path id="ov2mb" d="M332.02 695.833h12.607"></path>
    </defs>
    <g>
      <g transform="translate(-331 -689)">
        <path
          fill="#fff"
          fill-opacity="0"
          stroke="#363636"
          stroke-linecap="round"
          stroke-miterlimit="50"
          stroke-width="1.8"
          id="ov2ma"
          d="M337.365 690L332 695.8l5.331 5.765"
        ></path>
      </g>
      <g transform="translate(-331 -689)">
        <path
          id="ov2mb"
          d="M332.02 695.833h12.607"
          fill="#fff"
          fill-opacity="0"
          stroke="#363636"
          stroke-linecap="round"
          stroke-miterlimit="50"
          stroke-width="1.8"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AppGoBackIcon',
};
</script>
