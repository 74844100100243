<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 28C12.2385 28 8 23.7615 8 18.5C8 13.2385 12.2385 9 17.5 9C22.7615 9 27 13.2385 27 18.5C27 23.7615 22.7615 28 17.5 28ZM17.5 10.4615C13.0423 10.4615 9.46154 14.0423 9.46154 18.5C9.46154 22.9577 13.0423 26.5385 17.5 26.5385C21.9577 26.5385 25.5385 22.9577 25.5385 18.5C25.5385 14.0423 21.9577 10.4615 17.5 10.4615Z"
      :fill="color"
    />
    <path
      d="M24.9523 24L30.5239 29.5716C30.7868 29.8345 30.7868 30.2609 30.5239 30.5239C30.2609 30.7868 29.8345 30.7868 29.5716 30.5239L24 24.9523L24.9523 24Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheSearchIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.inverse ? '#fff' : '#454545';
    },
  },
};
</script>
