<template>
  <a v-if="isExternal && to" :href="to" rel="noopener nofollow" :target="openInAnotherTab !== false ? '_blank' : null">
    <slot></slot>
  </a>
  <RouterLink v-else-if="isExternal === false && to" :target="openInAnotherTab === true ? '_blank' : null" :to="to">
    <slot></slot>
  </RouterLink>
  <span v-else class="app-no-link" :class="{ 'app-no-link--show': $slots.default }">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'AppLink',
  props: {
    external: {
      type: Boolean,
      default: null,
    },
    openInAnotherTab: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      required: true,
    },
  },
  computed: {
    isExternal() {
      if (typeof this.external === 'boolean') {
        return this.external;
      }
      if (typeof this.to !== 'string') {
        return false;
      }
      return this.to.indexOf('http') === 0 || this.to.indexOf('mailto') === 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-no-link {
  &:not(&--show) {
    visibility: hidden;
  }
}
</style>
