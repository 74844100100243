import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import AppConfig from '@/config';
import fragmentMatcher from '@/config/fragmentMatcher';

Vue.use(VueApollo);

const httpEndpoint = AppConfig.getAsString('baseUrl', '') + '/graphql/protected';

export function createApolloProvider() {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        accept: 'application/json',
        authorization: `Bearer ${store.state.token}`,
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (networkError) {
      const err: any = networkError;

      if (err.statusCode === 403 && store.state.isAuth && store.getters.isUserMembershipLapsed) {
        router.replace({ name: AppConfig.getAsString('redirectUsersWithLapsedMembershipTo') });
        return;
      }

      if ((err.statusCode === 401 || err.statusCode === 403) && store.state.isAuth) {
        store.dispatch('logout', { notSendRequest: true }).then(() => {
          router.replace({ name: 'login' });
        });
      }
    }
  });

  const httpLink = createHttpLink({
    uri: httpEndpoint,
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({ fragmentMatcher }),
    connectToDevTools: AppConfig.getAsString('env') !== 'production',
    defaultOptions: {
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
      },
    },
    link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  });

  return new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'no-cache',
      },
    },
    errorHandler() {},
  });
}
