<template>
  <EntityCardLabel v-bind="$attrs" class="entity-card-label" label="Staff pick" h-align="right">
    <template #icon>
      <TheStarIcon />
    </template>
  </EntityCardLabel>
</template>

<script>
import EntityCardLabel from '@/components/partials/EntityCardLabel.vue';
import TheStarIcon from '@/components/icons/TheStarIcon.vue';

export default {
  name: 'StaffPickLabel',
  inheritAttrs: false,
  components: { EntityCardLabel, TheStarIcon },
};
</script>
