<template>
  <RouterLink :to="routeTo">
    <div class="search-destination">
      <div class="search-destination__text">
        <span class="search-destination__text-hint">{{ hint }}</span>
        <span class="search-destination__text-title">{{ entity.title }}</span>
      </div>
      <button class="search-destination__go-ahead back-btn" type="button">
        <AppGoBackIcon />
      </button>
    </div>
  </RouterLink>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';

import AppGoBackIcon from '@/components/icons/AppGoBackIcon.vue';

export default {
  name: 'AppSearchResultSitePage',
  components: { AppGoBackIcon },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    hint: {
      type: String,
      default: 'View site page',
    },
  },
  computed: {
    routeTo() {
      return this.entity.url || getEntityRoute(this.entity);
    },
  },
};
</script>

<style lang="scss" scoped></style>
