<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path id="yb33a" d="M1385 68.13V41.88h1.88v26.25z"></path>
      <path id="yb33b" d="M1372.82 54.06h26.25v1.88h-26.25z"></path>
    </defs>
    <g>
      <g transform="translate(-1376 -45)">
        <use :fill="color" xlink:href="#yb33a"></use>
      </g>
      <g transform="translate(-1376 -45)">
        <use :fill="color" xlink:href="#yb33b"></use>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheCrossIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.inverse ? '#fff' : '#454545';
    },
  },
};
</script>

<style scoped>
svg {
  transform: rotate(45deg);
}
</style>
