<template>
  <div class="events-slider">
    <div
      class="d-flex align-items-center justify-content-between mobile-bottom events-slider__head"
      :data-aos="appearingWithAnimation ? 'fade-up' : undefined"
    >
      <div v-if="title" class="title">{{ title }}</div>
      <RouterLink v-if="showSeeAllLink && events.length > slideMode" class="see-all" :to="seeAllRoute"
        >See all</RouterLink
      >
    </div>
    <div
      v-if="!searchResults && events.length <= slideMode"
      class="row events-slider__group px-3"
      :data-aos="appearingWithAnimation ? 'fade-up' : undefined"
    >
      <AppEventSliderItem
        v-for="event in events"
        :key="`events-slider-${event.__typename}_${event.id}`"
        :event="event"
      />
    </div>
    <div
      v-else-if="searchResults && events.length <= slideMode"
      class="search-results-simple"
      :data-aos="appearingWithAnimation ? 'fade-up' : undefined"
    >
      <AppEventSliderItem
        v-for="event in events"
        :key="`events-slider_${event.__typename}_${event.id}`"
        :event="event"
        :display-as-row-col="false"
      />
    </div>

    <AppSwiper
      v-else
      :data="events"
      :data-aos="appearingWithAnimation ? 'fade-up' : undefined"
      :data-type="type"
      :disable-padding-x="false"
      :has-more-data="hasMoreData"
      :route="routerPathToSingle"
      :title-event="title"
      :use-animation-effect="false"
    />
  </div>
</template>

<script>
import handleResizeWithDebounce from '@/helpers/windowResizeHandlerWithDebounce';
import WHATS_HAPPENING_TYPES from '@/models/whatsHappeningTypes';

import AppSwiper from '@/components/partials/elements/AppSwiper';
import AppEventSliderItem from '@/components/partials/sliders/AppEventSliderItem';
import { getEntityRoute } from '@/helpers/entityType';

export default {
  name: 'AppEventSlider',
  components: {
    AppSwiper,
    AppEventSliderItem,
  },
  props: {
    appearingWithAnimation: {
      type: Boolean,
      default: true,
    },
    destination: {
      type: Object,
      default: null,
    },
    events: {
      type: Array,
      required: true,
    },
    hasMoreData: {
      type: Boolean,
      default: false,
    },
    routerPathToSingle: {
      type: String,
    },
    searchResults: {
      type: Boolean,
      default: false,
    },
    showSeeAllLink: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      slideMode: 3,
    };
  },

  computed: {
    seeAllRoute() {
      if (this.type === WHATS_HAPPENING_TYPES.historicalSite && this.destination !== null) {
        return getEntityRoute(this.destination, { query: { tab: 'art_spaces' }, defaultValue: {} });
      }
      if (this.type === WHATS_HAPPENING_TYPES.miniCultivist) {
        return { name: 'miniCultivists' };
      }

      const path = this.title.toLocaleLowerCase().replace(' ', '-');
      let query = {};

      if (!['academies'].includes(this.type) && this.destination !== null) {
        query.destinations = this.destination.slug;
      }

      if (this.type === WHATS_HAPPENING_TYPES.artSpace) {
        return { path: '/art-spaces', query: { ...query } };
      }

      return { path, query };
    },
  },

  created() {
    this.setSlideMode();

    this._debounceResize = handleResizeWithDebounce();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    this._debounceResize = null;
  },

  methods: {
    handleResize() {
      this._debounceResize(this.setSlideMode);
    },
    setSlideMode() {
      this.slideMode = window.innerWidth > 768 ? 3 : 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-results-simple {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  padding: 0 15px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
