<template>
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.09563 14.7811L1.46852 8.4556C1.38973 8.36384 1.29548 8.29065 1.19127 8.2403C1.08706 8.18995 0.974979 8.16344 0.861567 8.16234C0.748154 8.16123 0.63568 8.18554 0.530709 8.23384C0.425738 8.28215 0.330371 8.35349 0.250173 8.44369C0.169975 8.5339 0.106552 8.64117 0.063605 8.75924C0.0206579 8.87731 -0.000953276 9.00382 3.225e-05 9.13139C0.00101778 9.25895 0.0245805 9.38502 0.0693453 9.50224C0.11411 9.61945 0.179181 9.72546 0.26076 9.81409L7.09392 17.5L20.7602 2.12818C20.9158 1.94698 21.0019 1.70429 21 1.45239C20.998 1.20049 20.9082 0.959523 20.7498 0.781394C20.5915 0.603265 20.3772 0.502225 20.1533 0.500036C19.9293 0.497847 19.7136 0.594684 19.5525 0.769691L7.09563 14.7811Z"
      fill="#363636"
    />
  </svg>
</template>

<script>
export default {
  name: 'CheckedMarkIcon',
};
</script>
