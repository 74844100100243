<template>
  <svg width="17" height="23" viewBox="0 0 17 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.81492 21.4028H16.1851C16.3942 21.4034 16.5951 21.4823 16.7465 21.6233C16.8979 21.7642 16.9882 21.9564 16.9989 22.1604C17.0096 22.3644 16.9399 22.5647 16.8041 22.72C16.6683 22.8754 16.4767 22.974 16.2688 22.9955L16.1851 23H0.81492C0.605804 22.9993 0.404897 22.9204 0.253519 22.7795C0.10214 22.6386 0.0117991 22.4463 0.00107638 22.2423C-0.00964633 22.0383 0.0600645 21.8381 0.195871 21.6828C0.331678 21.5274 0.523255 21.4288 0.731201 21.4072L0.81492 21.4028H16.1851H0.81492ZM8.25277 0.00383336L8.33649 0C8.75966 0 9.10826 0.314333 9.15012 0.716833L9.15405 0.798611V16.3172L12.8475 12.7101C12.9229 12.6338 13.0131 12.573 13.1129 12.5312C13.2127 12.4893 13.3199 12.4673 13.4285 12.4664C13.537 12.4655 13.6447 12.4858 13.7452 12.526C13.8456 12.5662 13.9369 12.6255 14.0136 12.7005C14.0904 12.7755 14.1511 12.8647 14.1921 12.9628C14.2332 13.061 14.2539 13.1662 14.2529 13.2722C14.2519 13.3782 14.2293 13.483 14.1865 13.5804C14.1436 13.6779 14.0813 13.766 14.0032 13.8396L8.91597 18.8089C8.76267 18.9585 8.55483 19.0426 8.33812 19.0426C8.12142 19.0426 7.91357 18.9585 7.76027 18.8089L2.67242 13.8396C2.51908 13.6898 2.43293 13.4867 2.43293 13.2748C2.43293 13.063 2.51908 12.8598 2.67242 12.7101C2.82576 12.5603 3.03374 12.4761 3.2506 12.4761C3.46746 12.4761 3.67544 12.5603 3.82878 12.7101L7.51893 16.3147V0.798611C7.51893 0.38525 7.84072 0.0447223 8.25277 0.00383336L8.33649 0L8.25277 0.00383336Z"
      fill="#363636"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheDownloadIcon',
};
</script>
