import { Dictionary } from 'vue-router/types/router';

interface RouteEntity {
  __typename: string;
  id: string | number;
  slug?: string;
  destination?: { id: string | number; slug: string; parent?: { id: string | number; slug: string } };
  insiderTip?: RouteEntity;
  type?: keyof typeof EntityTypes;
  url?: string;
}

export enum EntityTypes {
  academy = 'AcademyType',
  art_fair = 'ArtFairType',
  art_guide = 'ArtGuideType',
  art_space = 'ArtSpaceType',
  art_trip = 'ArtTripType',
  destination = 'DestinationType',
  event = 'EventType',
  exhibition = 'ExhibitionType',
  historical_site = 'HistoricalSiteType',
  insider_tip = 'InsiderTipType',
  insider_tip_element = 'InsiderTipElementType',
  member_site_page = 'MemberSitePage',
  mini_cultivist = 'MiniCultivistType',
  story = 'StoryType',
  virtual_library_item = 'VirtualLibraryItem',
}

const entityTypeRouteNames: { [key: string]: string } = {
  [EntityTypes.academy]: 'academy',
  [EntityTypes.art_fair]: 'art-fair',
  [EntityTypes.art_guide]: 'art-guide',
  [EntityTypes.art_space]: 'art-space',
  [EntityTypes.art_trip]: 'art-trip',
  [EntityTypes.destination]: 'destination',
  [EntityTypes.event]: 'event',
  [EntityTypes.exhibition]: 'exhibition',
  [EntityTypes.historical_site]: 'historical-site',
  [EntityTypes.insider_tip]: 'insider-tip',
  [EntityTypes.insider_tip_element]: 'insider-tip',
  [EntityTypes.mini_cultivist]: 'miniCultivist',
  [EntityTypes.story]: 'story',
};

const getDestinationSlugFromEntity = (entity: RouteEntity) => {
  const destination = entity.destination;

  return destination?.parent ? destination?.parent.slug : destination?.slug;
};

const getEntityRouteByGraphqlTypename = (
  rawEntity: RouteEntity,
  options: { defaultValue?: any; query?: Dictionary<string | (string | null)[]> }
) => {
  options.defaultValue = options.defaultValue || null;
  options.query = options.query || {};

  let type =
    rawEntity.__typename === 'ArtGuideEventType' && rawEntity.type ? EntityTypes[rawEntity.type] : rawEntity.__typename;

  if (type === EntityTypes.member_site_page) {
    return rawEntity.url;
  }

  let entity = rawEntity;
  let entityWithDestination = entity;

  if (type === EntityTypes.insider_tip_element) {
    type = EntityTypes.insider_tip;

    if (rawEntity.insiderTip) {
      entity = rawEntity.insiderTip;
    }
  }

  const entityIdSlugExist = !!(entity && (entity.slug || entity.insiderTip?.slug || entity.id));

  if (!entityIdSlugExist) {
    return options.defaultValue;
  }

  const extraParams: { destinationSlug?: string } = {};

  if (
    [EntityTypes.historical_site, EntityTypes.insider_tip, EntityTypes.insider_tip_element].includes(
      type as EntityTypes
    )
  ) {
    extraParams.destinationSlug = getDestinationSlugFromEntity(entityWithDestination);
  }

  const routeName: string | undefined = entityTypeRouteNames[type];

  return routeName
    ? {
        name: routeName,
        params: { ...extraParams, id: entity.id, slug: entity.slug },
        query: options.query,
      }
    : options.defaultValue;
};

const getEntityTypeByTypename = (entity: { __typename: string }): string => {
  const type = entity.__typename
    .replace(/Type$/, '')
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase();

  return type.charAt(0).toUpperCase() + type.slice(1);
};

export function getEntityRoute(
  entity: RouteEntity,
  options: { defaultValue?: any; query?: Dictionary<string | (string | null)[]> } = {}
): any {
  if (entity.__typename) {
    return getEntityRouteByGraphqlTypename(entity, options);
  }
  return options.defaultValue;
}

export function getEntityType(entity: { __typename: string }): string {
  if (entity.__typename) {
    return getEntityTypeByTypename(entity);
  }
  return '';
}
