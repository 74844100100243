import { render, staticRenderFns } from "./MsgInboxActiveMessage.vue?vue&type=template&id=36cb0610&scoped=true&"
import script from "./MsgInboxActiveMessage.vue?vue&type=script&lang=js&"
export * from "./MsgInboxActiveMessage.vue?vue&type=script&lang=js&"
import style0 from "./MsgInboxActiveMessage.vue?vue&type=style&index=0&id=36cb0610&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36cb0610",
  null
  
)

export default component.exports