import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import fragmentArtType from './fragmentArtType';

export default new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [fragmentArtType],
    },
  },
});
