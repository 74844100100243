import apiProtectedRequest from '@/api/apiProtectedRequest';

export const getDestinationClosestToUser = (latitude: number | null, longitude: number | null) => {
  return new Promise((resolve, reject) => {
    apiProtectedRequest
      .post('user/closest-destination', {
        latitude,
        longitude,
      })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject({ data: err.response.data, status: err.response.status });
      });
  });
};
