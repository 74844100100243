<template>
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.5131 19.7017C29.4259 19.8057 28.9768 19.7502 28.6215 19.5838C28.5574 19.556 28.4877 19.5452 28.4186 19.5525C28.3495 19.5598 28.2832 19.5848 28.2259 19.6254C28.1685 19.665 28.1215 19.7187 28.0887 19.7815C28.056 19.8444 28.0387 19.9146 28.0382 19.986V28.8627C28.0382 29.0014 27.931 29.1193 27.8036 29.1193H23.0841V21.9C23.0841 21.7853 23.0405 21.6753 22.9627 21.5935C22.885 21.5118 22.7793 21.4649 22.6684 21.4631H18.331C18.2202 21.4649 18.1145 21.5118 18.0367 21.5935C17.9589 21.6753 17.9154 21.7853 17.9154 21.9V29.1193H13.2026C13.0685 29.1193 12.9679 29.0014 12.9679 28.8627V19.986C12.9681 19.9135 12.9509 19.842 12.9177 19.7782C12.8845 19.7143 12.8365 19.6599 12.778 19.62C12.7195 19.5801 12.6523 19.5559 12.5824 19.5496C12.5126 19.5432 12.4423 19.555 12.378 19.5838C12.0629 19.7363 11.5735 19.8126 11.4863 19.7017C11.3728 19.5623 11.317 19.3823 11.3308 19.2006C11.3446 19.0189 11.4269 18.8501 11.5601 18.7308L20.0875 11.033C20.1981 10.9307 20.3413 10.8741 20.4897 10.8741C20.6381 10.8741 20.7813 10.9307 20.8919 11.033L20.9858 11.1024L29.4326 18.7377C29.5648 18.8559 29.6471 19.0227 29.6622 19.2028C29.6772 19.3828 29.6237 19.5618 29.5131 19.7017V19.7017ZM29.9824 18.0789L21.4684 10.3742C21.4366 10.3451 21.4004 10.3217 21.3611 10.3049C21.0966 10.0958 20.7701 9.98835 20.4374 10.001C20.1047 10.0137 19.7867 10.1456 19.5377 10.3742L11.0171 18.072C10.4003 18.6267 10.3266 19.6046 10.8428 20.2564C11.1713 20.6725 11.7143 20.6725 12.13 20.5824V28.8627C12.13 29.4868 12.6059 29.9931 13.2026 29.9931H18.3377C18.5656 29.9931 18.7534 29.7989 18.7534 29.5562V22.3439H22.2461V29.5631C22.2461 29.8058 22.4338 30 22.6684 30H27.8036C28.3936 30 28.8762 29.4938 28.8762 28.8696V20.5893C29.2919 20.6726 29.8348 20.6795 30.1566 20.2634C30.6728 19.6115 30.5991 18.6337 29.989 18.0858L29.9824 18.0789Z"
      :fill="color"
    />
    <path
      d="M29.5131 19.7017C29.4259 19.8057 28.9768 19.7502 28.6215 19.5838C28.5574 19.556 28.4877 19.5452 28.4186 19.5525C28.3495 19.5598 28.2832 19.5848 28.2259 19.6254C28.1685 19.665 28.1215 19.7187 28.0887 19.7815C28.056 19.8444 28.0387 19.9146 28.0382 19.986V28.8627C28.0382 29.0014 27.931 29.1193 27.8036 29.1193H23.0841V21.9C23.0841 21.7853 23.0405 21.6753 22.9627 21.5935C22.885 21.5118 22.7793 21.4649 22.6684 21.4631H18.331C18.2202 21.4649 18.1145 21.5118 18.0367 21.5935C17.9589 21.6753 17.9154 21.7853 17.9154 21.9V29.1193H13.2026C13.0685 29.1193 12.9679 29.0014 12.9679 28.8627V19.986C12.9681 19.9135 12.9509 19.842 12.9177 19.7782C12.8845 19.7143 12.8365 19.6599 12.778 19.62C12.7195 19.5801 12.6523 19.5559 12.5824 19.5496C12.5126 19.5432 12.4423 19.555 12.378 19.5838C12.0629 19.7363 11.5735 19.8126 11.4863 19.7017C11.3728 19.5623 11.317 19.3823 11.3308 19.2006C11.3446 19.0189 11.4269 18.8501 11.5601 18.7308L20.0875 11.033C20.1981 10.9307 20.3413 10.8741 20.4897 10.8741C20.6381 10.8741 20.7813 10.9307 20.8919 11.033L20.9858 11.1024L29.4326 18.7377C29.5648 18.8559 29.6471 19.0227 29.6622 19.2028C29.6772 19.3828 29.6237 19.5618 29.5131 19.7017V19.7017ZM29.9824 18.0789L21.4684 10.3742C21.4366 10.3451 21.4004 10.3217 21.3611 10.3049C21.0966 10.0958 20.7701 9.98835 20.4374 10.001C20.1047 10.0137 19.7867 10.1456 19.5377 10.3742L11.0171 18.072C10.4003 18.6267 10.3266 19.6046 10.8428 20.2564C11.1713 20.6725 11.7143 20.6725 12.13 20.5824V28.8627C12.13 29.4868 12.6059 29.9931 13.2026 29.9931H18.3377C18.5656 29.9931 18.7534 29.7989 18.7534 29.5562V22.3439H22.2461V29.5631C22.2461 29.8058 22.4338 30 22.6684 30H27.8036C28.3936 30 28.8762 29.4938 28.8762 28.8696V20.5893C29.2919 20.6726 29.8348 20.6795 30.1566 20.2634C30.6728 19.6115 30.5991 18.6337 29.989 18.0858L29.9824 18.0789Z"
      :stroke="color"
      stroke-width="0.5"
      stroke-miterlimit="50"
    />
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.inverse ? '#fff' : '#363636';
    },
  },
};
</script>
