<template>
  <div class="page-wrapper container">
    <h1 class="page-header">Opps, this page does not exist...</h1>
    <RouterLink class="btn border-btn" to="/">Back to Home</RouterLink>
  </div>
</template>

<script>
export default {
  name: 'ThePageNotFound',
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  position: relative;
  top: 100px;

  @media (min-width: 768px) {
    top: 140px;
  }
}

.page-header {
  margin-bottom: 50px;
  font-size: 24px;
  color: #363636;

  @media (min-width: 768px) {
    margin-bottom: 80px;
    font-size: 32px;
  }
}

.border-btn {
  display: block;
  max-width: 250px;
  margin: auto;
  padding: 16px;
  font-size: 16px;
}
</style>
