import Vue from 'vue';
import App from './App.vue';
import './classComponentHooks';
import './registerServiceWorker';
import router from './router';
import store from './store';

import VueMeta from 'vue-meta';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Vuelidate from 'vuelidate';
import VueInfiniteLoading from 'vue-infinite-loading';
import Vue2TouchEvents from 'vue2-touch-events';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueGtag from 'vue-gtag';
import vClickOutside from 'v-click-outside';
import Toast from 'vue-toastification';

import AppConfig from '@/config';
import AppLoader from './components/partials/AppLoader.vue';

import { createApolloProvider } from './apollo-setup';

Vue.use(VueMeta);
Vue.use(VueInfiniteLoading, {
  props: {
    spinner: 'spiral',
  },
  slots: {
    error: '',
    noMore: '',
    noResults: '',
  },
});
Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
});
Vue.use(VueAwesomeSwiper);
Vue.use(Vuelidate);
Vue.use(require('vue-moment'));
Vue.use(vClickOutside);
Vue.use(
  VueGtag,
  {
    appName: 'The Cultivist Members',
    config: { id: AppConfig.getAsString('googleAnalyticsId') },
    pageTrackerScreenviewEnabled: true,
    pageTrackerUseFullPath: true,
  },
  router
);
Vue.use(Toast);

Vue.component('AppLoader', AppLoader);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  apolloProvider: createApolloProvider(),
  mounted() {
    AOS.init({ offset: 70 });
  },
  render: (h) => h(App),
}).$mount('#app');
