import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import { EntityTypes } from '@/helpers/entityType';
import HomePage from '@/views/HomePage.vue';
import ThePageNotFound from '@/views/errors/ThePageNotFound.vue';

Vue.use(VueRouter);

interface IdParamsRouteBuilder {
  id?: boolean;
  optional?: boolean;
}

const generateIdSlugRoutePathOptions = (path: string) => ({
  path: buildIdSlugRoutePath(path),
  alias: buildIdSlugRoutePath(path, { id: false }),
});

const buildIdSlugRoutePath = (path: string, idParams: IdParamsRouteBuilder = {}) => {
  idParams = { id: true, optional: true, ...idParams };

  if (idParams.id) {
    let replaceValue = ':slug-000:id(\\d+)';

    replaceValue = idParams.optional ? replaceValue + '?' : replaceValue;
    path = path.replace(':slug', replaceValue);
  }

  return path;
};

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "LoginPage" */ '@/views/auth/LoginPage.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "ForgotPasswordPage" */ '@/views/auth/ForgotPasswordPage.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/activate',
    name: 'activate-account',
    component: () => import(/* webpackChunkName: "ActivateAccountPage" */ '@/views/auth/ActivateAccountPage.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "ResetPasswordPage" */ '@/views/auth/ResetPasswordPage.vue'),
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: HomePage,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "EventsPage" */ '@/views/EventsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/events/:slug'),
    name: 'event',
    component: () => import(/* webpackChunkName: "EventSinglePage" */ '@/views/EventSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: buildIdSlugRoutePath('/events/:slug/reviews', { optional: false }),
    name: 'event-reviews',
    component: () => import(/* webpackChunkName: "DataItemReviewsPage" */ '@/views/DataItemReviewsPage.vue'),
    props: { entityType: EntityTypes.event },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/exhibitions',
    name: 'exhibitions',
    props: true,
    component: () => import(/* webpackChunkName: "ExhibitionsPage" */ '@/views/ExhibitionsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/exhibitions/:slug'),
    name: 'exhibition',
    component: () => import(/* webpackChunkName: "ExhibitionSinglePage" */ '@/views/ExhibitionSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: buildIdSlugRoutePath('/exhibitions/:slug/reviews', { optional: false }),
    name: 'exhibitions-reviews',
    component: () => import(/* webpackChunkName: "DataItemReviewsPage" */ '@/views/DataItemReviewsPage.vue'),
    props: { entityType: EntityTypes.exhibition },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/minicultivist',
    name: 'miniCultivists',
    component: () => import(/* webpackChunkName: "MiniCultivistsPage" */ '@/views/MiniCultivistsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/minicultivist/:slug'),
    name: 'miniCultivist',
    component: () => import(/* webpackChunkName: "MiniCultivistSinglePage" */ '@/views/MiniCultivistSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: buildIdSlugRoutePath('/minicultivist/:slug/reviews', { optional: false }),
    name: 'mini-cultivists-reviews',
    component: () => import(/* webpackChunkName: "DataItemReviewsPage" */ '@/views/DataItemReviewsPage.vue'),
    props: { entityType: EntityTypes.mini_cultivist },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/academy',
    name: 'academies',
    component: () => import(/* webpackChunkName: "AcademiesPage" */ '@/views/AcademiesPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/academy/:slug'),
    name: 'academy',
    component: () => import(/* webpackChunkName: "AcademySinglePage" */ '@/views/AcademySinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: buildIdSlugRoutePath('/academy/:slug/reviews', { optional: false }),
    name: 'academy-reviews',
    component: () => import(/* webpackChunkName: "DataItemReviewsPage" */ '@/views/DataItemReviewsPage.vue'),
    props: { entityType: EntityTypes.academy },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/art-fairs',
    name: 'art-fairs',
    component: () => import(/* webpackChunkName: "ArtFairsPage" */ '@/views/ArtFairsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/art-fairs/:slug'),
    name: 'art-fair',
    component: () => import(/* webpackChunkName: "ArtFairSinglePage" */ '@/views/ArtFairSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: buildIdSlugRoutePath('/art-fairs/:slug/reviews', { optional: false }),
    name: 'art-fair-reviews',
    component: () => import(/* webpackChunkName: "DataItemReviewsPage" */ '@/views/DataItemReviewsPage.vue'),
    props: { entityType: EntityTypes.art_fair },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/art-trips',
    name: 'art-trips',
    component: () => import(/* webpackChunkName: "ArtTripsPage" */ '@/views/ArtTripsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/art-trips/:slug'),
    name: 'art-trip',
    component: () => import(/* webpackChunkName: "ArtTripSinglePage" */ '@/views/ArtTripSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/art-spaces',
    name: 'art-spaces',
    props: true,
    component: () => import(/* webpackChunkName: "ArtSpacesPage" */ '@/views/ArtSpacesPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/art-spaces/:slug'),
    name: 'art-space',
    component: () => import(/* webpackChunkName: "ArtSpaceSinglePage" */ '@/views/ArtSpaceSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: buildIdSlugRoutePath('/art-spaces/:slug/reviews', { optional: false }),
    name: 'art-space-reviews',
    component: () => import(/* webpackChunkName: "DataItemReviewsPage" */ '@/views/DataItemReviewsPage.vue'),
    props: { entityType: EntityTypes.art_space },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/stories',
    name: 'stories',
    component: () => import(/* webpackChunkName: "StoriesPage" */ '@/views/StoriesPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/stories/:slug'),
    name: 'story',
    component: () => import(/* webpackChunkName: "StorySinglePage" */ '@/views/StorySinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/destinations',
    name: 'destinations',
    component: () => import(/* webpackChunkName: "DestinationsPage" */ '@/views/DestinationsPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/destinations/:slug'),
    name: 'destination',
    props: true,
    component: () => import(/* webpackChunkName: "DestinationSinglePage" */ '@/views/DestinationSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: buildIdSlugRoutePath('/destinations/:slug/map', { optional: false }),
    name: 'destination-map',
    component: () => import(/* webpackChunkName: "DestinationMapPage" */ '@/views/DestinationMapPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/destinations/:destinationSlug/insider-tips/:slug'),
    name: 'insider-tip',
    props: true,
    component: () => import(/* webpackChunkName: "InsiderTipSinglePage" */ '@/views/InsiderTipSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/destinations/:destinationSlug/sites',
    redirect: (to) => {
      return { name: 'destination', params: { slug: to.params.destinationSlug }, query: { tab: 'art_spaces' } };
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/destinations/:destinationSlug/sites/:slug'),
    name: 'historical-site',
    props: true,
    component: () => import(/* webpackChunkName: "HistoricalSitePage" */ '@/views/HistoricalSitePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/all-art-guides',
    name: 'all-art-guides',
    component: () => import(/* webpackChunkName: "MyArtGuidesPage" */ '@/views/MyArtGuidesPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    ...generateIdSlugRoutePathOptions('/art-guide/:slug'),
    name: 'art-guide',
    props: true,
    component: () => import(/* webpackChunkName: "MyArtGuideSinglePage" */ '@/views/MyArtGuideSinglePage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "ProfilePage" */ '@/views/ProfilePage.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "ProfileDetails" */ '@/views/account/ProfileDetails.vue'),
        meta: {
          requiresAuth: true,
          allowForLapsedMembership: true,
        },
      },
      {
        path: 'contact-preferences',
        name: 'Contact-preferences',
        component: () => import(/* webpackChunkName: "ContactPreferences" */ '@/views/account/ContactPreferences.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'interested-in',
        name: 'Interested-in',
        component: () => import(/* webpackChunkName: "IamInterestedIn" */ '@/views/account/IamInterestedIn.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import(/* webpackChunkName: "Notifications" */ '@/views/account/Notifications.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'special-request',
        name: 'special-request',
        component: () => import(/* webpackChunkName: "AddSpecRequest" */ '@/views/account/AddSpecRequest.vue'),
        meta: {
          requiresAuth: true,
          disableForEnthusiast: true,
        },
      },
      {
        path: 'my-membership',
        name: 'my-membership',
        component: () => import(/* webpackChunkName: "MyMembership" */ '@/views/account/MyMembership.vue'),
        meta: {
          requiresAuth: true,
          allowForLapsedMembership: true,
        },
      },
      {
        path: 'payment',
        name: 'payment-details',
        component: () => import(/* webpackChunkName: "PaymentDetails" */ '@/views/account/PaymentDetails.vue'),
        meta: {
          requiresAuth: true,
          allowForLapsedMembership: true,
        },
      },
      {
        path: 'member-liaison',
        name: 'member-liaison',
        component: () => import(/* webpackChunkName: "MemberLiaison" */ '@/views/account/MemberLiaison.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'delete',
        name: 'delete-account',
        component: () => import(/* webpackChunkName: "MemberLiaison" */ '@/views/account/DeleteAccount.vue'),
        meta: {
          requiresAuth: true,
          allowForLapsedMembership: true,
        },
      },
    ],
  },
  {
    path: '/my-agenda',
    name: 'My Agenda',
    component: () => import(/* webpackChunkName: "MyAgendaPage" */ '@/views/MyAgendaPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/all-favorites',
    name: 'all-favorites',
    component: () => import(/* webpackChunkName: "MyFavoritesPage" */ '@/views/MyFavoritesPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "ContactPage" */ '@/views/ContactPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: ThePageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.query.tab || (from.query.tab && to.name === 'destination')) {
      return;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest);
  const disableForEnthusiast = to.matched.some((record) => record.meta.disableForEnthusiast);
  const allowForLapsedMembership = to.matched.some((record) => record.meta.allowForLapsedMembership);

  const isAuthenticated = store.state.isAuth;
  const isEnthusiastUser = store.state.museumRole;
  const isUserMembershipLapsed = store.getters.isUserMembershipLapsed;

  if (requiresAuth && !isAuthenticated) {
    const query: { redirect?: string } = {};
    if (to.fullPath !== '/') {
      query.redirect = to.fullPath;
    }
    next({ name: 'login', query });
    return;
  }

  if (requiresAuth && isAuthenticated && isUserMembershipLapsed && !allowForLapsedMembership) {
    store.dispatch('showOrHideRenewMembershipModalShown', true);
    next({ name: 'payment-details' });
    return;
  }

  if (disableForEnthusiast && isEnthusiastUser) {
    if (to.fullPath.includes('/account')) {
      next({ name: 'Profile' });
    } else {
      next({ name: 'home' });
    }
    return;
  }

  if (requiresGuest && isAuthenticated) {
    next('/');
    return;
  }
  next();
});

export default router;
