import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import MobileDetect from 'mobile-detect';
import apiRequest from '@/api/apiRequest';
import apiProtectedRequest from '@/api/apiProtectedRequest';

Vue.use(Vuex);

interface MembershipSubscriptionState {
  cancel_at_formatted: string | null;
  interval: string;
  is_cancelled: boolean;
  is_monthly: boolean;
  is_yearly: boolean;
  price_for_human: string;
  price_hint: string;
}

interface MembershipState {
  card_expiry: string;
  expiry_date: string;
  isEnthusiast: boolean;
  isFullMember: boolean;
  is_active: boolean;
  is_lapsed_or_paused: boolean;
  member_since: string;
  number: string;
  subscription: MembershipSubscriptionState;
  type: string;
  upgrade_to_annual_prices: string;
}

interface UserState {
  avatar?: string;
  email?: string;
  id?: number;
  name?: string;
}

interface VuexState {
  destinationReview: string;
  fcmToken: string;
  isAppDataFilterOpened: boolean;
  isAppleDevice: boolean;
  isAuth: boolean;
  isMainMenuShown: boolean;
  isMainSearchModalShown: boolean;
  isMobileScreen: boolean;
  isMobileScreenExclude768: boolean;
  isMsgInboxShown: boolean;
  isPermissionsModalShown: boolean;
  isRenewMembershipModalShown: boolean;
  isReviewFilter: boolean;
  keepLogin: boolean;
  loader: boolean;
  logInDate: string;
  membership: MembershipState;
  museumRole: boolean;
  showLoaderScreen: boolean;
  token: string;
  unreadAppMessagesCount: number;
  user: UserState;
}

const vuexLocal = new VuexPersistence({
  saveState: (key, state) => {
    const appState = { ...state } as VuexState;
    let storage: Storage;

    if (appState.keepLogin) {
      storage = window.localStorage;
      window.sessionStorage.removeItem(key);
    } else {
      storage = window.sessionStorage;
      window.localStorage.removeItem(key);
    }
    storage.setItem(key, JSON.stringify(appState));
  },
  restoreState: (key) => {
    return JSON.parse(window.localStorage.getItem(key) || window.sessionStorage.getItem(key) || '{}');
  },
});

const MOBILE_MAX_WIDTH = 768;

const getDefaultState = (): VuexState => {
  return {
    destinationReview: '',
    fcmToken: '',
    isAppDataFilterOpened: false,
    isAppleDevice: false,
    isAuth: false,
    isMainMenuShown: false,
    isMainSearchModalShown: false,
    isMobileScreen: false,
    isMobileScreenExclude768: false,
    isMsgInboxShown: false,
    isPermissionsModalShown: false,
    isRenewMembershipModalShown: false,
    isReviewFilter: true,
    keepLogin: false,
    loader: false,
    logInDate: '',
    membership: {
      card_expiry: '',
      expiry_date: '',
      isEnthusiast: false,
      isFullMember: false,
      is_active: false,
      is_lapsed_or_paused: false,
      member_since: '',
      number: '',
      subscription: {
        cancel_at_formatted: null,
        interval: '',
        is_cancelled: false,
        is_monthly: false,
        is_yearly: false,
        price_for_human: '',
        price_hint: '',
      },
      type: '',
      upgrade_to_annual_prices: '',
    },
    museumRole: false,
    showLoaderScreen: false,
    token: '',
    unreadAppMessagesCount: 0,
    user: {},
  };
};

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    ...getDefaultState(),
  },
  getters: {
    isUserMembershipActive(state) {
      return state.membership.is_active;
    },
    isUserMembershipLapsed(state) {
      return state.membership.is_lapsed_or_paused;
    },
  },
  actions: {
    login({ commit }, { email, password, keepMeLoggedIn }) {
      return new Promise<void>((resolve, reject) => {
        apiRequest
          .post('auth/web/login', {
            email,
            password,
            keep_me_logged_in: keepMeLoggedIn,
          })
          .then((res: object) => {
            commit('setLoginSession', res);
            resolve();
          })
          .catch((error: any) => {
            reject(error.response);
          });
      });
    },
    logout({ commit }, payload) {
      if (payload && payload.notSendRequest) {
        commit('resetState');
        return;
      }
      return apiProtectedRequest.post('auth/logout').then(() => {
        commit('resetState');
      });
    },
    deleteAccount({ commit, state }, { password }) {
      return new Promise((resolve, reject) => {
        apiProtectedRequest
          .delete(`users/${state.user.id}`, {
            data: { password },
          })
          .then((res: object) => {
            commit('resetState');
            resolve(res);
          })
          .catch((error: any) => {
            reject(error.response);
          });
      });
    },
    setLoader({ commit }, value) {
      commit('setLoader', value);
    },
    showLoaderScreen({ commit }, value) {
      commit('showLoaderScreen', value);
    },
    setFcmToken({ commit }, token) {
      commit('setFcmToken', token);
    },
    setUserMembership({ commit }, membership) {
      commit('setUserMembership', membership);
    },
    setUnreadAppMessagesCount({ commit }, count) {
      commit('setUnreadAppMessagesCount', count);
    },
    reviewToogle({ commit }, value) {
      commit('reviewToogle', value);
    },
    destinationReview({ commit }, destination) {
      commit('destinationReview', destination);
    },
    toggleAppDataFilter({ commit, dispatch }, isOpened = null) {
      if (isOpened && this.getters.isUserMembershipLapsed) {
        dispatch('showOrHideRenewMembershipModalShown', true);
        return;
      }
      commit('toggleAppDataFilter', isOpened);
    },
    determineIsMobileScreen({ commit }, width) {
      commit('setIsMobileScreen', {
        isMobile: width <= MOBILE_MAX_WIDTH,
        isMobileExclude768: width < MOBILE_MAX_WIDTH,
      });
    },
    showOrHidePermissionsModal({ commit }, value) {
      commit('showOrHidePermissionsModal', value);
    },
    showOrHideRenewMembershipModalShown({ commit }, value) {
      commit('showOrHideRenewMembershipModalShown', value);
    },
    checkIsAppleDevice({ commit }) {
      const md = new MobileDetect(window.navigator.userAgent);
      commit('setIsAppleDevice', md.is('iPhone') || md.is('iPad'));
    },
    showMainMenu({ commit, dispatch, state }, value: boolean) {
      commit('showMainMenu', value);

      if (value && state.isMobileScreen) {
        dispatch('disableScroll');
      } else {
        dispatch('enableScroll');
      }
    },
    showMainSearchModal({ commit, dispatch }, value: boolean) {
      if (value && this.getters.isUserMembershipLapsed) {
        dispatch('showOrHideRenewMembershipModalShown', true);
        dispatch('enableScroll');
        return;
      }

      commit('showMainSearchModal', value);

      if (value) {
        dispatch('disableScroll');
      } else {
        dispatch('enableScroll');
      }
    },
    showMsgInbox({ commit, dispatch }, value: boolean) {
      if (value && this.getters.isUserMembershipLapsed) {
        dispatch('showOrHideRenewMembershipModalShown', true);
        return;
      }

      commit('showMsgInbox', value);

      if (value) {
        dispatch('showMainMenu', false);
      }
    },
    setUser({ commit }, payload) {
      commit('setUser', {
        id: payload?.id,
        email: payload?.email,
        name: payload?.name,
        avatar: payload?.profile_picture_full_url,
      });
    },
    enableScroll() {
      document.body.classList.remove('scroll-disabled');
    },
    disableScroll() {
      document.body.classList.add('scroll-disabled');
    },
  },
  mutations: {
    setIsAppleDevice(state, value) {
      state.isAppleDevice = !!value;
    },
    setLoginSession(state, { data, status }) {
      if (status == 200) {
        state.isAuth = true;
        state.token = data.token;
        state.logInDate = new Date().getTime() + '';
        state.keepLogin = data.keep_me_logged_in;

        state.membership.is_active = data.user.membership.is_active;
        state.membership.is_lapsed_or_paused = data.user.membership.is_lapsed_or_paused;
        state.membership.isEnthusiast = state.museumRole = data.user.membership.is_enthusiast;
        state.membership.isFullMember = data.user.membership.is_full_member;
      }
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setToken(state, token) {
      state.token = token;
    },
    setLogInDate(state, logInDate) {
      state.logInDate = logInDate;
    },
    setFcmToken(state, token) {
      state.fcmToken = token;
    },
    setUserMembership(state, membership) {
      const membershipState = { ...membership };
      delete membershipState.__typename;

      if (!membershipState.subscription) {
        membershipState.subscription = { ...getDefaultState().membership.subscription };
      } else {
        delete membershipState.subscription.__typename;
      }
      state.membership = membershipState;
      state.museumRole = membershipState.isEnthusiast;
    },
    setUnreadAppMessagesCount(state, count) {
      state.unreadAppMessagesCount = count;
    },
    reviewToogle(state, value) {
      state.isReviewFilter = value;
    },
    destinationReview(state, destination) {
      state.destinationReview = destination;
    },
    toggleAppDataFilter(state, isOpened = null) {
      isOpened = isOpened === null ? !state.isAppDataFilterOpened : !!isOpened;
      state.isAppDataFilterOpened = isOpened;
    },
    setLoader(state, value) {
      state.loader = !!value;
    },
    showLoaderScreen(state, value) {
      state.showLoaderScreen = !!value;
    },
    setIsMobileScreen(state, { isMobile, isMobileExclude768 }) {
      state.isMobileScreen = !!isMobile;
      state.isMobileScreenExclude768 = !!isMobileExclude768;
    },
    showOrHidePermissionsModal(state, value) {
      state.isPermissionsModalShown = value;
    },
    showOrHideRenewMembershipModalShown(state, value) {
      state.isRenewMembershipModalShown = value;
    },
    showMainMenu(state, value: boolean) {
      state.isMainMenuShown = value;
    },
    showMainSearchModal(state, value: boolean) {
      state.isMainSearchModalShown = value;
    },
    showMsgInbox(state, value: boolean) {
      state.isMsgInboxShown = value;
    },
    setUser(state, user: UserState) {
      state.user = user;
    },
  },
});
