<template>
  <div class="left-menu message-slide">
    <div class="desktop-header message-header">
      <div class="icon-mobile" :class="{ 'icon-with-media': message.media }" @click="close">
        <TheArrowHorizIcon />
      </div>
    </div>

    <div v-if="message.media" class="message-slide__photo bg" :style="`background-image: url(${message.media.url})`" />

    <div class="message-slide__info">
      <p class="message-slide__title">{{ message.message_title }}</p>
      <div class="message-slide__text" v-html="message.message_body" />

      <div v-if="actions.length" class="message-slide__text">
        <AppLink
          v-for="act in actions"
          :key="act.type"
          class="btn border-btn text-uppercase msg-action"
          :to="act.routeTo"
          >{{ act.name }}</AppLink
        >
      </div>
    </div>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';

import AppLink from '@/components/AppLink.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';

export default {
  name: 'MsgInboxActiveMessage',
  components: { AppLink, TheArrowHorizIcon },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actions() {
      return this.message.actions
        .map((a) => {
          if (a.type === 'requestForFeedback') {
            return {
              ...a,
              routeTo: `mailto:${a.feedback_email}?subject=${encodeURIComponent(a.feedback_subject)}`,
            };
          }

          let query = null;

          if (a.type === 'updateBooking') {
            query = { 'update-tour-booking': true };
          } else if (a.type === 'cancelBooking') {
            query = { 'cancel-tour-booking': true };
          }

          return a.event ? { ...a, routeTo: getEntityRoute(a.event, { query }) } : a;
        })
        .filter((a) => a.event);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.message-header {
  padding: 0 30px;
}

.msg-action {
  padding: 20px 16px;
  font-size: 18px;
}

.icon-mobile {
  cursor: pointer;

  &.icon-with-media {
    display: flex;
    align-items: center;
    width: 26px;
    height: 26px;
    padding: 6px;
    background-color: #fff;
    border-radius: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.color {
    svg {
      * {
        stroke: #fff !important;
      }
    }
  }
}
</style>
