




























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import ImageHandler from '@/helpers/ImageHandler';
import { getEntityRoute } from '@/helpers/entityType';
import { getEntityCardLabel, getEntityCardLabelForStaffPickHorizAlign } from '@/helpers/entityCardLabel';
import { formatDate } from '@/helpers/dateTimeHelper';

import AppRouteLink from '@/components/partials/AppRouteLink.vue';
import DataItemDates from '@/components/partials/DataItemDates.vue';
import EntityCardLabel from '@/components/partials/EntityCardLabel.vue';
import StaffPickLabel from '@/components/partials/StaffPickLabel.vue';

@Component({
  components: { StaffPickLabel, EntityCardLabel, AppRouteLink, DataItemDates },
  computed: {
    ...mapState(['museumRole']),
  },
})
export default class DataItem extends Vue {
  @Prop() data!: any;
  @Prop() routes!: string;
  @Prop() routeId!: number;
  @Prop() itemType: any;
  @Prop(Boolean) inDestination: boolean;
  @Prop(Boolean) isSearchComponent: boolean;
  @Prop({ default: true, type: Boolean }) animation: boolean;
  @Prop({ default: false, type: Boolean }) searchMode: boolean;
  @Prop({ default: null }) routeEntity: string;

  get dates() {
    if (this.data.date && this.data.date.length) {
      return this.data.date;
    }
    return [
      {
        start_date: this.data.start_date || null,
        end_date: this.data.end_date || null,
      },
    ];
  }

  get entityCardLabel() {
    return getEntityCardLabel(this.data);
  }

  get entityCardLabelForStaffPickHorizAlign() {
    return getEntityCardLabelForStaffPickHorizAlign(this.data);
  }

  get getImage() {
    return new ImageHandler().getFirstImgFromMedia(this.data);
  }

  get routeTo() {
    return getEntityRoute(this.data);
  }

  get showData() {
    if (this.searchMode) {
      return true;
    }
    if (!this.museumRole) {
      return true;
    }
    if (this.$route.name === 'art-spaces') {
      return this.data.accessType && this.data.accessType.id !== 4;
    }
    return this.data.title !== 'Permanent Collection';
  }

  get startPublishingAt() {
    return formatDate(this.data.startPublishingAt);
  }

  get titleFromArtSpace() {
    return this.data?.artSpace?.title || '';
  }
}
