<template>
  <div v-if="startDate" class="d-flex">
    <span class="small-grey">{{ startDate }}</span>
    <span v-if="endDate && !isEqual" class="small-grey ml-1"> - {{ endDate }}</span>
  </div>
</template>

<script>
export default {
  name: 'DataItemDates',
  props: {
    dates: {
      type: Array,
      default: () => [{ start_date: null, end_date: null }],
    },
  },
  computed: {
    endDate() {
      const moments = this.dates.map((d) => (d.end_date ? this.$moment(d.end_date) : null)).filter((el) => el);

      return moments.length ? this.$moment.max(moments).format('DD MMM YYYY') : null;
    },
    isEqual() {
      return this.endDate === this.startDate;
    },
    startDate() {
      const startDate = this.dates[0].start_date;

      return startDate ? this.$moment(startDate).format('DD MMM YYYY') : null;
    },
  },
};
</script>
