<template>
  <section class="menu-group" :class="{ 'menu-group--opened': showItems }">
    <header class="menu-group__header" @click="toggleItems">
      <ArrowCaretIcon class="menu-group__caret-icon" />
      <h4 class="menu-group__name">{{ name }}</h4>
    </header>

    <CollapseTransition>
      <ul v-show="showItems" class="menu-group-list">
        <li v-for="(item, idx) in items" :key="`main-menu-item-${id}-${idx}`" class="menu-group-list__item">
          <RouterLink class="menu-group-list__link" :to="item.route">{{ item.name }}</RouterLink>
        </li>
      </ul>
    </CollapseTransition>
  </section>
</template>

<script>
import ArrowCaretIcon from '@/components/ArrowCaretIcon.vue';
import CollapseTransition from '@/components/transitions/CollapseTransition.vue';

export default {
  name: 'AppMainMenuGroup',
  components: { ArrowCaretIcon, CollapseTransition },
  props: {
    id: {
      type: Number,
      required: true,
    },
    isMenuClosed: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showItems: false,
    };
  },
  watch: {
    isMenuClosed(value) {
      if (value) {
        this.showItems = false;
      }
    },
  },
  methods: {
    toggleItems() {
      this.showItems = !this.showItems;
    },
  },
};
</script>
