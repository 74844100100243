import axios from 'axios';
import store from '@/store';
import AppConfig from '@/config';

const APP_BASE_URL = AppConfig.getAsString('baseUrl', '') + '/';
const APP_BASE_API_URL = `${APP_BASE_URL}api/`;

const apiProtectedRequest = axios.create({
  baseURL: APP_BASE_API_URL,
  withCredentials: true,
});

apiProtectedRequest.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${store.state.token}`;
  }

  return config;
});

export default apiProtectedRequest;
