<template>
  <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8 3.78788C13.8 3.18511 13.5577 2.60704 13.1263 2.18082C12.695 1.7546 12.11 1.51515 11.5 1.51515C10.89 1.51515 10.305 1.7546 9.87365 2.18082C9.44232 2.60704 9.2 3.18511 9.2 3.78788H7.66667C7.66667 2.78327 8.07053 1.81981 8.78942 1.10944C9.50831 0.399079 10.4833 0 11.5 0C12.5167 0 13.4917 0.399079 14.2106 1.10944C14.9295 1.81981 15.3333 2.78327 15.3333 3.78788H22.2333C22.4367 3.78788 22.6317 3.86769 22.7754 4.00977C22.9192 4.15184 23 4.34453 23 4.54545C23 4.74638 22.9192 4.93907 22.7754 5.08114C22.6317 5.22321 22.4367 5.30303 22.2333 5.30303H21.3839L19.3967 22.3182C19.3101 23.0567 18.9519 23.738 18.3902 24.2324C17.8284 24.7269 17.1023 25.0001 16.3499 25H6.65007C5.89773 25.0001 5.17161 24.7269 4.60984 24.2324C4.04807 23.738 3.68985 23.0567 3.60333 22.3182L1.6146 5.30303H0.766667C0.587228 5.30309 0.413452 5.24095 0.275603 5.12744C0.137754 5.01393 0.0445655 4.85623 0.0122665 4.68182L0 4.54545C0 4.34453 0.0807737 4.15184 0.224552 4.00977C0.368329 3.86769 0.563334 3.78788 0.766667 3.78788H13.8ZM19.8383 5.30303H3.1602L5.12593 22.1439C5.16921 22.5133 5.34843 22.8541 5.62947 23.1013C5.91052 23.3486 6.27377 23.4851 6.65007 23.4848H16.3499C16.726 23.4847 17.0888 23.348 17.3696 23.1008C17.6503 22.8536 17.8293 22.5131 17.8725 22.1439L19.8383 5.30303ZM9.2 9.09091C9.57567 9.09091 9.89 9.32576 9.9544 9.63485L9.96667 9.75455V19.0348C9.96667 19.4 9.6232 19.697 9.2 19.697C8.82433 19.697 8.51 19.4621 8.4456 19.153L8.43333 19.0333V9.75606C8.43333 9.38939 8.7768 9.09242 9.2 9.09242V9.09091ZM13.8 9.09091C14.1757 9.09091 14.49 9.32576 14.5544 9.63485L14.5667 9.75455V19.0348C14.5667 19.4 14.2232 19.697 13.8 19.697C13.4243 19.697 13.11 19.4621 13.0456 19.153L13.0333 19.0333V9.75606C13.0333 9.38939 13.3768 9.09242 13.8 9.09242V9.09091Z"
      fill="#363636"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheTrashIcon',
};
</script>
