<template>
  <div class="wrapper">
    <div class="loader">
      <AppLoader inverse />
    </div>
    <div class="overlay" />
  </div>
</template>

<script>
export default {
  name: 'AppLoaderScreen',
};
</script>

<style scoped lang="scss">
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1001;
  background-color: rgba(#000, 0.5);
}
.loader {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
