<template>
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.375 23.3771V30.0837L20.5 26.0597M9.5 18.6825L31.5 9.91699L27.65 29.413L9.5 18.6825ZM31.5 9.96394L16.375 23.3771L31.5 9.96394Z"
      :stroke="color"
      stroke-width="1.375"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'InboxMsgIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.inverse ? '#fff' : '#363636';
    },
  },
};
</script>
