<template>
  <component v-on="$listeners" :is="routeTo ? 'router-link' : fallbackTag" :to="routeTo">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'AppRouteLink',
  props: {
    fallbackTag: {
      type: String,
      default: 'div',
    },
    routeTo: {
      type: [Object, String],
    },
  },
};
</script>
