<template>
  <div>
    <Transition name="fade">
      <div v-if="value" class="app-main-menu-overlay" @click="handleClose" />
    </Transition>

    <Transition name="slide" @after-enter="handleAfterOpen" @after-leave="handleAfterClose">
      <div v-show="value" id="appMainMenu" class="app-main-menu">
        <div class="app-main-menu__section">
          <div class="app-main-menu__top">
            <div class="app-main-menu__actions-wrapper">
              <RouterLink to="/">
                <HomeIcon class="menu-home-icon" />
              </RouterLink>

              <div class="app-main-menu__actions menu-actions">
                <slot name="actions"></slot>
              </div>
            </div>
            <button class="app-main-menu__btn-close menu-btn-close" @click="handleClose">
              <TheCrossIcon class="menu-btn-close__icon" />
            </button>
          </div>

          <header class="app-main-menu__profile menu-profile">
            <RouterLink to="/account/profile">
              <img class="menu-profile__img" :src="user.avatar" alt="Avatar" width="48" height="48" />
            </RouterLink>
            <div class="menu-profile__text profile-text">
              <h4 class="profile-text__name">{{ user.name }}</h4>
              <RouterLink class="profile-text__label" to="/account/profile">Profile</RouterLink>
            </div>
          </header>
        </div>

        <div
          v-for="(group, idx) in menuItemGroups"
          :key="`main-menu-group-${idx}`"
          class="app-main-menu__section app-main-menu__section--py-0"
        >
          <AppMainMenuGroup :id="idx" :is-menu-closed="isClosed" :items="group.items" :name="group.name" />
        </div>

        <footer class="app-main-menu__section app-main-menu__section--bottom-links">
          <ul class="menu-bottom-links">
            <li class="menu-bottom-links__item">
              <ExternalLink
                class="menu-bottom-links__link"
                href="https://www.thecultivist.com/memberships-options"
                new-tab
                >Memberships</ExternalLink
              >
            </li>
            <li class="menu-bottom-links__item">
              <ExternalLink class="menu-bottom-links__link" href="https://www.thecultivist.com/about-us" new-tab
                >About</ExternalLink
              >
            </li>
            <li class="menu-bottom-links__item">
              <RouterLink class="menu-bottom-links__link" to="/contact-us">Contact us</RouterLink>
            </li>
            <li class="menu-bottom-links__item">
              <a class="menu-bottom-links__link" href="#" @click.prevent="handleLogout">Sign out</a>
            </li>
          </ul>
        </footer>
      </div>
    </Transition>
  </div>
</template>

<script>
import menuItemGroups from '@/models/mainMenuItemGroups';
import AppMainMenuGroup from '@/components/AppMainMenuGroup.vue';
import ExternalLink from '@/components/ExternalLink.vue';
import HomeIcon from '@/components/icons/HomeIcon.vue';
import TheCrossIcon from '@/components/icons/TheCrossIcon.vue';
import TheSearchIcon from '@/components/icons/TheSearchIcon.vue';

export default {
  name: 'AppMainMenu',
  components: { HomeIcon, TheSearchIcon, AppMainMenuGroup, ExternalLink, TheCrossIcon },
  props: {
    user: {
      type: Object,
      default: () => ({ id: undefined, name: '', avatar: '' }),
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isClosed: true,
      menuItemGroups,
    };
  },
  methods: {
    handleAfterOpen() {
      this.isClosed = false;
    },
    handleAfterClose() {
      this.isClosed = true;
    },
    handleClose() {
      this.$emit('close');
    },
    handleLogout() {
      this.$emit('logout');
    },
  },
};
</script>
