<template>
  <div class="d-flex">
    <RouterLink :to="routeTo">
      <div class="data-item__img" :style="`background-image:url(${mediaUrl})`" />
    </RouterLink>

    <RouterLink :to="routeTo">
      <div class="description">
        <p v-if="item.title" class="title">{{ item.title }}</p>

        <div v-if="titleFromArtSpace" class="small-grey type">{{ titleFromArtSpace }}</div>

        <div v-if="item && item.date && item.date[0]" class="small-grey type">
          <template v-if="startDate">{{ startDate }}</template>
          <template v-if="endDate && !areDatesEqual"> - {{ endDate }}</template>
        </div>

        <div v-if="publishDate" class="small-grey type">{{ publishDate }}</div>
        <div v-if="item.organizerTitle" class="small-grey type">{{ item.organizerTitle }}</div>

        <div v-if="item.__typename === 'ArtSpaceType' && item.type" class="small-grey type">{{ item.type }}</div>

        <div v-else-if="item.__typename !== 'StoryType' && item.destination" class="d-flex align-items-center">
          <div class="small-grey type">{{ item.destination.title }},</div>
          <div v-show="item.destination.country" class="small-grey type">{{ item.destination.country }}</div>
        </div>
      </div>
    </RouterLink>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { formatDate } from '@/helpers/dateTimeHelper';

export default {
  name: 'AppSearchResultItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    areDatesEqual() {
      return this.endDate === this.startDate;
    },
    endDate() {
      return formatDate(this.item.date[0].end_date);
    },
    mediaUrl() {
      return new ImageHandler().getFirstImgFromMedia(this.item);
    },
    publishDate() {
      return formatDate(this.item.startPublishingAt);
    },
    routeTo() {
      return getEntityRoute(this.item);
    },
    startDate() {
      return formatDate(this.item.date[0].start_date);
    },
    titleFromArtSpace() {
      return this.item?.artSpace?.title || '';
    },
  },
};
</script>
