<template>
  <div class="events-slider">
    <div
      class="d-flex align-items-center justify-content-between mobile-bottom events-slider__head"
      :data-aos="appearingWithAnimation ? 'fade-up' : undefined"
    >
      <div v-if="title" class="title">{{ title }}</div>
      <RouterLink v-if="events.length > slideMode" class="see-all" :to="{ name: 'miniCultivists' }">See all</RouterLink>
    </div>

    <div
      v-if="events.length <= slideMode"
      class="row events-slider__group px-3"
      :data-aos="appearingWithAnimation ? 'fade-up' : undefined"
    >
      <AppEventSliderItem
        v-for="event in events"
        :key="`events-slider_mc_${event.id}`"
        :event="event"
        :route-path="routerPathToSingle"
      >
        <template #body="{ dates, publishDate }">
          <div v-if="event.type !== 'Event'" class="d-flex flex-column">
            <div class="small-grey">{{ event.type }}</div>
            <div class="small-grey mt-1">{{ publishDate }}</div>
          </div>
          <template v-else>
            <div v-if="event.destination" class="d-flex">
              <div v-if="event.destination.title" class="small-grey">{{ event.destination.title }},</div>
              <div v-if="event.destination.country" class="small-grey ml-1">{{ event.destination.country }}</div>
            </div>
            <DataItemDates class="mt-1" :dates="dates" />
          </template>
        </template>
      </AppEventSliderItem>
    </div>

    <AppSwiper
      v-else
      :data="events"
      :data-aos="appearingWithAnimation ? 'fade-up' : undefined"
      :disable-padding-x="false"
      :has-more-data="hasMoreData"
      :route="routerPathToSingle"
      :title-event="title"
      :use-animation-effect="false"
    />
  </div>
</template>

<script>
import handleResizeWithDebounce from '@/helpers/windowResizeHandlerWithDebounce';

import AppSwiper from '@/components/partials/elements/AppSwiper';
import AppEventSliderItem from '@/components/partials/sliders/AppEventSliderItem';
import DataItemDates from '@/components/partials/DataItemDates';

export default {
  name: 'AppMiniCultivistSlider',
  components: {
    AppSwiper,
    AppEventSliderItem,
    DataItemDates,
  },
  props: {
    appearingWithAnimation: {
      type: Boolean,
      default: true,
    },
    events: {
      type: Array,
      required: true,
    },
    hasMoreData: {
      type: Boolean,
      default: false,
    },
    routerPathToSingle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      slideMode: 3,
    };
  },

  created() {
    this.setSlideMode();

    this._debounceResize = handleResizeWithDebounce();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    this._debounceResize = null;
  },

  methods: {
    handleResize() {
      this._debounceResize(this.setSlideMode);
    },
    setSlideMode() {
      this.slideMode = window.innerWidth > 768 ? 3 : 1;
    },
  },
};
</script>
