import axios from 'axios';
import AppConfig from '@/config';

const APP_BASE_URL = AppConfig.getAsString('baseUrl', '') + '/';
const APP_BASE_API_URL = `${APP_BASE_URL}api/`;

const apiRequest = axios.create({
  baseURL: APP_BASE_API_URL,
  withCredentials: true,
});

export default apiRequest;
