<template>
  <div class="home-page main-content with-mobile-header">
    <AppMainPageSlider :slides="mainPageSlides" />

    <div class="container">
      <div class="row">
        <div class="col-12">
          <AppCitySelectList
            v-if="destinationsForSelectList.length"
            :cities="destinationsForSelectList"
            :current-city="currentDestination"
            @selected="changeCurrentDestination"
          />
        </div>
      </div>

      <AppEventSlider
        v-if="resultResponse.events.data.length"
        class="contentEvents"
        title="Events"
        :has-more-data="resultResponse.events.hasMorePages"
        :destination="currentDestination"
        :events="resultResponse.events.data"
        router-path-to-single="events"
        :type="whatsHappeningTypes.event"
      />
      <AppEventSlider
        v-if="resultResponse.exhibitions.data.length"
        class="contentExhibition"
        title="Exhibitions"
        :destination="currentDestination"
        :events="resultResponse.exhibitions.data"
        :has-more-data="resultResponse.exhibitions.hasMorePages"
        router-path-to-single="exhibitions"
        :type="whatsHappeningTypes.exhibition"
      />
      <AppEventSlider
        v-if="resultResponse.artFairs.data.length"
        class="contentArtFairs"
        title="Art Fairs"
        :destination="currentDestination"
        :has-more-data="resultResponse.artFairs.hasMorePages"
        :events="resultResponse.artFairs.data"
        router-path-to-single="art-fairs"
        :type="whatsHappeningTypes.artFair"
      />
      <AppMiniCultivistSlider
        v-if="resultResponse.miniCultivistList.data.length"
        class="contentMiniCultivist"
        title="Mini Cultivist"
        :has-more-data="resultResponse.miniCultivistList.hasMorePages"
        :events="resultResponse.miniCultivistList.data"
        router-path-to-single="miniCultivist"
        :type="whatsHappeningTypes.miniCultivist"
      />
      <AppEventSlider
        v-if="resultResponse.artSpaces.data.length"
        class="contentArtSpace"
        title="Museums & Galleries"
        :destination="currentDestination"
        :has-more-data="resultResponse.artSpaces.hasMorePages"
        :events="resultResponse.artSpaces.data"
        router-path-to-single="art-spaces"
        :type="whatsHappeningTypes.artSpace"
      />
      <AppEventSlider
        v-if="resultResponse.stories.data.length"
        class="contentStory"
        title="Stories"
        :destination="currentDestination"
        :events="resultResponse.stories.data"
        :has-more-data="resultResponse.stories.hasMorePages"
        router-path-to-single="stories"
        :type="whatsHappeningTypes.story"
      />
      <AppEventSlider
        v-if="resultResponse.academies.data.length"
        class="contentAcademy"
        title="Academy"
        :destination="currentDestination"
        :events="resultResponse.academies.data"
        :has-more-data="resultResponse.academies.hasMorePages"
        router-path-to-single="academy"
        :type="whatsHappeningTypes.academy"
      />
    </div>
  </div>
</template>

<script>
import { getCurrentGeoPosition, navigatorCurrentPosition } from '@/helpers/GeolocationHelper';
import { getDestinationClosestToUser } from '@/helpers/destinationHelper';
import DEFAULT_CURRENT_DESTINATION from '@/models/defaultCurrentDestination';
import WHATS_HAPPENING_TYPES from '@/models/whatsHappeningTypes';
import { EntityTypes } from '@/helpers/entityType';

import mainPageSlidesQuery from '@/graphql/MainPageSlides.query.gql';
import destinationsQuery from '@/graphql/destination/DestinationsForSelectList.query.gql';
import academiesQuery from '@/graphql/academy/Academies.query.gql';
import artFairsQuery from '@/graphql/artFair/ArtFairsHappeningInDestination.query.gql';
import artSpacesQuery from '@/graphql/artSpace/ArtSpacesHappeningInDestination.query.gql';
import eventsQuery from '@/graphql/event/EventsHappeningInDestination.query.gql';
import exhibitionsQuery from '@/graphql/exhibition/ExhibitionsHappeningInDestination.query.gql';
import miniCultivistListQuery from '@/graphql/miniCultivist/MiniCultivistHappeningInDestination.query.gql';
import storiesQuery from '@/graphql/story/StoriesHappeningInDestination.query.gql';

import AppMainPageSlider from '@/components/partials/AppMainPageSlider';
import AppCitySelectList from '@/components/partials/AppCitySelectList';
import AppEventSlider from '@/components/partials/sliders/AppEventSlider';
import AppMiniCultivistSlider from '@/components/partials/sliders/AppMiniCultivistSlider';

const DATA_PER_PAGE = 6;

export default {
  name: 'HomePage',
  components: {
    AppMainPageSlider,
    AppCitySelectList,
    AppEventSlider,
    AppMiniCultivistSlider,
  },
  metaInfo: {
    title: 'The Cultivist Members',
    titleTemplate: null,
  },
  data() {
    return {
      currentDestination: DEFAULT_CURRENT_DESTINATION,
      currentDestinationInitialized: false,
      destinationsForSelectList: [],
      mainPageSlides: [],
      resultResponse: {
        academies: { data: [], hasMorePages: false, page: 1 },
        artFairs: { data: [], hasMorePages: false, page: 1 },
        artSpaces: { data: [], hasMorePages: false, page: 1 },
        artTrips: { data: [], hasMorePages: false, page: 1 },
        events: { data: [], hasMorePages: false, page: 1 },
        exhibitions: { data: [], hasMorePages: false, page: 1 },
        historicalSites: { data: [], hasMorePages: false, page: 1 },
        miniCultivistList: { data: [], hasMorePages: false, page: 1 },
        stories: { data: [], hasMorePages: false, page: 1 },
      },
    };
  },
  apollo: {
    mainPageSlides: {
      query: mainPageSlidesQuery,
    },
    destinationsForSelectList: {
      query: destinationsQuery,
    },
    academies: {
      query: academiesQuery,
      skip() {
        return !this.currentDestinationInitialized;
      },
      variables() {
        return {
          limit: DATA_PER_PAGE,
          page: this.resultResponse.academies.page,
        };
      },
      result({ data }) {
        this.resultResponse.academies.data.push(...data.academies.data);
        this.resultResponse.academies.hasMorePages = data.academies.hasMorePages;
      },
    },
    artFairs: {
      query: artFairsQuery,
      skip() {
        return !this.currentDestinationInitialized;
      },
      variables() {
        return {
          destinationId: this.currentDestination.id,
          limit: DATA_PER_PAGE,
          page: this.resultResponse.artFairs.page,
        };
      },
      result({ data }) {
        this.resultResponse.artFairs.data.push(...data.artFairs.data);
        this.resultResponse.artFairs.hasMorePages = data.artFairs.hasMorePages;
      },
    },
    artSpaces: {
      query: artSpacesQuery,
      skip() {
        return !this.currentDestinationInitialized;
      },
      variables() {
        return {
          destinationId: this.currentDestination.id,
          limit: DATA_PER_PAGE,
          page: this.resultResponse.artSpaces.page,
        };
      },
      result({ data }) {
        this.resultResponse.artSpaces.data.push(...data.artSpaces.data);
        this.resultResponse.artSpaces.hasMorePages = data.artSpaces.hasMorePages;
      },
    },
    events: {
      query: eventsQuery,
      skip() {
        return !this.currentDestinationInitialized;
      },
      variables() {
        return {
          destinationId: this.currentDestination.id,
          limit: DATA_PER_PAGE,
          page: this.resultResponse.events.page,
        };
      },
      result({ data }) {
        this.resultResponse.events.data.push(...data.events.data);
        this.resultResponse.events.hasMorePages = data.events.hasMorePages;
      },
    },
    exhibitions: {
      query: exhibitionsQuery,
      skip() {
        return !this.currentDestinationInitialized;
      },
      variables() {
        return {
          destinationId: this.currentDestination.id,
          limit: DATA_PER_PAGE,
          page: this.resultResponse.exhibitions.page,
        };
      },
      result({ data }) {
        this.resultResponse.exhibitions.data.push(...data.exhibitions.data);
        this.resultResponse.exhibitions.hasMorePages = data.exhibitions.hasMorePages;
      },
    },
    miniCultivistList: {
      query: miniCultivistListQuery,
      skip() {
        return !this.currentDestinationInitialized;
      },
      variables() {
        return {
          destinationId: this.currentDestination.id,
          limit: DATA_PER_PAGE,
          page: this.resultResponse.miniCultivistList.page,
        };
      },
      result({ data }) {
        this.resultResponse.miniCultivistList.data.push(...data.miniCultivistList.data);
        this.resultResponse.miniCultivistList.hasMorePages = data.miniCultivistList.hasMorePages;
      },
    },
    stories: {
      query: storiesQuery,
      skip() {
        return !this.currentDestinationInitialized;
      },
      variables() {
        return {
          destinationId: this.currentDestination.id,
          limit: DATA_PER_PAGE,
          page: this.resultResponse.stories.page,
        };
      },
      result({ data }) {
        this.resultResponse.stories.data.push(...data.stories.data);
        this.resultResponse.stories.hasMorePages = data.stories.hasMorePages;
      },
    },
  },
  computed: {
    whatsHappeningTypes() {
      return WHATS_HAPPENING_TYPES;
    },
  },
  async created() {
    navigatorCurrentPosition(
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', false);
        this.initCurrentDestination();
      },
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', true);
        this.initCurrentDestination();
      }
    );

    this.$root.$on('loadMore', (type) => {
      switch (type) {
        case WHATS_HAPPENING_TYPES.academy:
          this.resultResponse.academies.page++;
          break;
        case WHATS_HAPPENING_TYPES.artFair:
          this.resultResponse.artFairs.page++;
          break;
        case WHATS_HAPPENING_TYPES.artSpace:
          this.resultResponse.artSpaces.page++;
          break;
        case WHATS_HAPPENING_TYPES.artTrip:
          this.resultResponse.artTrips.page++;
          break;
        case WHATS_HAPPENING_TYPES.event:
          this.resultResponse.events.page++;
          break;
        case WHATS_HAPPENING_TYPES.exhibition:
          this.resultResponse.exhibitions.page++;
          break;
        case WHATS_HAPPENING_TYPES.historicalSite:
          this.resultResponse.historicalSites.page++;
          break;
        case WHATS_HAPPENING_TYPES.miniCultivist:
          this.resultResponse.miniCultivistList.page++;
          break;
        case WHATS_HAPPENING_TYPES.story:
          this.resultResponse.stories.page++;
          break;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off('loadMore');
  },
  methods: {
    async initCurrentDestination() {
      const { latitude, longitude } = getCurrentGeoPosition();
      let destination = DEFAULT_CURRENT_DESTINATION;

      try {
        destination = await getDestinationClosestToUser(latitude, longitude);
      } catch (e) {}

      this.setCurrentDestination(destination);
      this.currentDestinationInitialized = true;

      this.emitAnalyticsEventAfterCityChanging();
    },
    changeCurrentDestination(destination) {
      this.setCurrentDestination(destination);
      this.emitAnalyticsEventAfterCityChanging();
      this.resetFetchData();
    },
    setCurrentDestination(destination) {
      this.currentDestination = { ...destination, __typename: EntityTypes.destination };
    },
    emitAnalyticsEventAfterCityChanging() {
      this.$gtag.event('select_whats_happening_in', {
        event_category: 'engagement',
        event_label: 'Whats happening in ' + this.currentDestination.title,
      });
    },
    resetFetchData() {
      for (const property in this.resultResponse) {
        if (property !== 'academies') {
          this.resultResponse[property].data = [];
          this.resultResponse[property].hasMorePages = false;
          this.resultResponse[property].page = 1;
        }
      }
    },
  },
};
</script>
