export default function handleResizeWithDebounce() {
  let WINDOW_RESIZE_TIMER_ID: number;

  function debounce(callback: Function, time = 100) {
    if (WINDOW_RESIZE_TIMER_ID) {
      clearTimeout(WINDOW_RESIZE_TIMER_ID);
    }
    WINDOW_RESIZE_TIMER_ID = setTimeout(callback, time);
  }

  return debounce;
}
