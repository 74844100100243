<template>
  <span class="icon">
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.999999L7 7L13 1" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'ArrowCaretIcon',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.inverse ? '#fff' : '#363636';
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
