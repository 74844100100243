export default {
  kind: 'UNION',
  name: 'ArtType',
  possibleTypes: [
    { name: 'ArtTripType' },
    { name: 'AcademyType' },
    { name: 'ArtSpaceType' },
    { name: 'ExhibitionType' },
    { name: 'ArtFairType' },
    { name: 'EventType' },
  ],
};
