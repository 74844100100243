<template>
  <svg width="257" height="15" viewBox="0 0 257 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.17756 14.6183H3.39167V2.01643H0V0.389648H8.56922V2.01643H5.17756V14.6183Z" :fill="color" />
    <path
      d="M28.8232 6.12122V0.374023H30.6053V14.6179H28.8232V7.78237H22.0169V14.6179H20.2349V0.374023H22.0169V6.12122H28.8232Z"
      :fill="color"
    />
    <path
      d="M43.5972 0.374023H51.2619V2.0008H45.3831V5.96847H51.1048V7.59907H45.3831V12.9949H51.2773V14.6179H43.6125L43.5972 0.374023Z"
      :fill="color"
    />
    <path
      d="M73.9612 5.15903C73.5785 4.66164 73.0842 4.26056 72.5178 3.988C71.9514 3.71543 71.3288 3.57895 70.6999 3.58953C68.4234 3.58953 66.8905 5.32705 66.8905 7.5381C66.8905 9.74915 68.4464 11.4103 70.7574 11.4103C71.37 11.3993 71.9732 11.2571 72.5259 10.9936C73.0787 10.7301 73.5682 10.3513 73.9612 9.8828V14.3202C72.7272 14.702 71.8381 14.9846 70.6807 14.9846C68.6924 14.9774 66.7786 14.2296 65.3154 12.8881C64.5637 12.2101 63.9688 11.3774 63.5718 10.4478C63.1747 9.51809 62.9851 8.5136 63.0159 7.50373C63.0086 5.55749 63.765 3.68548 65.1237 2.28734C66.5634 0.838196 68.5194 0.0146464 70.5657 -0.00390625C71.7332 0.00630811 72.8866 0.250462 73.9574 0.714018L73.9612 5.15903Z"
      :fill="color"
    />
    <path
      d="M99.0672 0.374023V8.39337C99.0672 10.2264 98.9944 12.0403 97.5688 13.4188C96.3769 14.5912 94.5028 15.0074 92.8319 15.0074C91.161 15.0074 89.2869 14.5912 88.0912 13.4188C86.6694 12.0403 86.5928 10.2264 86.5928 8.39337V0.374023H90.3102V7.87784C90.3102 9.72993 90.4405 11.7539 92.8319 11.7539C95.2233 11.7539 95.3536 9.72993 95.3536 7.87784V0.374023H99.0672Z"
      :fill="color"
    />
    <path d="M115.55 11.4865H120.004V14.6179H111.833V0.374023H115.55V11.4865Z" :fill="color" />
    <path d="M137.315 14.6179H133.601V3.51303H130.535V0.374023H140.381V3.51303H137.315V14.6179Z" :fill="color" />
    <path d="M155.875 14.6179H152.157V0.374023H155.875V14.6179Z" :fill="color" />
    <path
      d="M174.707 9.02329L178.179 0.366211H182.215L176.11 14.6101H173.228L167.207 0.37385H171.246L174.707 9.02329Z"
      :fill="color"
    />
    <path d="M197.276 14.6179H193.559V0.374023H197.276V14.6179Z" :fill="color" />
    <path
      d="M217.967 4.0594C217.279 3.47198 216.409 3.13886 215.503 3.11617C214.821 3.11617 213.909 3.49805 213.909 4.30762C213.909 5.11719 214.913 5.47616 215.557 5.66709L216.507 5.94968C218.496 6.53776 220.033 7.53827 220.033 9.84097C220.033 11.2577 219.692 12.7127 218.553 13.6597C217.402 14.5682 215.967 15.0441 214.499 15.0039C212.696 14.9855 210.942 14.4193 209.471 13.3809L211.065 10.3947C211.996 11.2043 213.092 11.8687 214.365 11.8687C215.235 11.8687 216.166 11.4296 216.166 10.4291C216.166 9.42854 214.706 9.03522 213.909 8.80609C211.575 8.14545 210.042 7.54209 210.042 4.78114C210.042 1.89036 212.107 0.00390625 214.97 0.00390625C216.534 0.0330992 218.069 0.433699 219.446 1.17244L217.967 4.0594Z"
      :fill="color"
    />
    <path d="M237.9 14.6179H234.182V3.51303H231.116V0.374023H240.973V3.51303H237.907L237.9 14.6179Z" :fill="color" />
    <path
      d="M257 12.7854C257.001 13.2228 256.871 13.6507 256.628 14.0148C256.384 14.3789 256.038 14.6629 255.633 14.8308C255.227 14.9988 254.781 15.0431 254.35 14.9582C253.92 14.8733 253.524 14.663 253.213 14.354C252.903 14.0449 252.691 13.6509 252.605 13.2219C252.519 12.7929 252.563 12.3482 252.731 11.9439C252.898 11.5397 253.183 11.1941 253.548 10.951C253.913 10.7079 254.342 10.5781 254.781 10.5781C255.369 10.5791 255.932 10.8119 256.348 11.2255C256.763 11.6391 256.998 12.1999 257 12.7854Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheOrganizationLogo',
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.inverse ? '#ffffff' : '#363636';
    },
  },
};
</script>
