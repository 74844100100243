<template>
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.9998 25.8333C14.8771 25.8333 13.7531 25.596 12.6611 25.1733"
      stroke="#363636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.8427 17.124C25.32 21.7894 20.66 25.8334 16 25.8334"
      stroke="#363636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.4385 12.3948C26.3598 13.4734 27.1785 14.6494 27.8425 15.8774C28.0518 16.2654 28.0518 16.7361 27.8425 17.1241"
      stroke="#363636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66699 25.8334L25.3337 7.16675"
      stroke="#363636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0308 19.4693C11.3908 17.8293 11.3908 15.1693 13.0308 13.5293C14.6708 11.8893 17.3308 11.8893 18.9708 13.5293"
      stroke="#363636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.725 9.77475C20.6623 8.17875 18.3303 7.16675 15.9997 7.16675C11.3397 7.16675 6.67967 11.2107 4.157 15.8774C3.94767 16.2654 3.94767 16.7361 4.157 17.1241C5.41833 19.4561 7.213 21.6321 9.27433 23.2268"
      stroke="#363636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'EyeClosedIcon',
};
</script>
