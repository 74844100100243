<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.61163 15.4435C3.22563 15.6415 2.78763 15.2945 2.86563 14.8515L3.69563 10.1215L0.17263 6.7655C-0.15637 6.4515 0.01463 5.8775 0.45563 5.8155L5.35363 5.1195L7.53763 0.7925C7.73463 0.4025 8.26763 0.4025 8.46463 0.7925L10.6486 5.1195L15.5466 5.8155C15.9876 5.8775 16.1586 6.4515 15.8286 6.7655L12.3066 10.1215L13.1366 14.8515C13.2146 15.2945 12.7766 15.6415 12.3906 15.4435L7.99963 13.1875L3.61163 15.4435Z"
      fill="#363636"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheStarIcon',
};
</script>
