<template>
  <div class="container mt-0" :class="[{ 'px-0': disablePaddingX }]" :data-aos="useAnimationEffect ? 'fade-up' : false">
    <div class="row">
      <div class="col-12">
        <div class="category-container">
          <div
            v-if="(data.length < 4 && !mobileMode) || (data.length < 4 && $route.name === 'art-fair' && !mobileMode)"
            class="row"
          >
            <div v-for="item in data" :key="item.id" class="col-sm-12 col-md-6 col-lg-4">
              <AppSwiperItem class="mb-4" :item="item" :related-mode="relatedMode" />
            </div>
          </div>

          <div v-if="mobileMode ? true : data.length > 3" class="category-swiper-wrap">
            <swiper :ref="titleEvent" :options="swiperOptions" @reachEnd="loadMore">
              <swiper-slide
                v-for="item in data"
                :key="item.id"
                :class="{
                  'full-width': isFullWidth,
                  'slider-width': $route.name === 'home' || $route.name === 'destination',
                }"
              >
                <div v-if="!relatedMode && $route.name !== 'destination'">
                  <DataItem
                    :animation="false"
                    :data="item"
                    :destinationSwiper="'swiper'"
                    :item-type="route"
                    :route-entity="goToPageLink(item)"
                    :search-mode="searchMode"
                  />
                </div>
                <div v-else>
                  <AppSwiperItem :item="item" :related-mode="relatedMode" />
                </div>
              </swiper-slide>
            </swiper>

            <button
              v-if="data.length >= 3"
              class="swiper-button-prev category-swiper__nav-btn category-swiper__nav-btn--prev"
              :data-prev="`prev-${titleEvent}`"
            />
            <button
              v-if="data.length >= 3"
              class="swiper-button-next category-swiper__nav-btn category-swiper__nav-btn--next"
              :data-next="`next-${titleEvent}`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';

import AppSwiperItem from '@/components/partials/elements/AppSwiperItem';
import DataItem from '@/components/partials/DataItem';

export default {
  name: 'AppSwiper',
  components: {
    AppSwiperItem,
    DataItem,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
    },
    disablePaddingX: {
      type: Boolean,
      default: true,
    },
    hasMoreData: {
      type: Boolean,
      default: false,
    },
    relatedMode: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
    },
    searchMode: {
      type: Boolean,
      default: false,
    },
    titleEvent: {
      type: String,
      required: true,
    },
    useAnimationEffect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mobileMode: false,
      swiperOptions: {
        breakpoints: {
          640: {
            slidesPerView: 'auto',
            centeredSlides: false,
            spaceBetween: 15,
          },
          991: {
            slidesPerView: 2,
            centeredSlides: false,
            spaceBetween: 30,
          },
        },
        loop: false,
        navigation: {
          prevEl: `.category-swiper__nav-btn--prev[data-prev="prev-${this.titleEvent}"]`,
          nextEl: `.category-swiper__nav-btn--next[data-next="next-${this.titleEvent}"]`,
        },
        slidesPerView: 3,
        spaceBetween: 30,
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs[this.titleEvent].swiper;
    },
    isFullWidth() {
      return this.$route.name !== 'art-fairs';
    },
  },
  created() {
    this.mobileMode = window.innerWidth < 768;
  },
  methods: {
    goToPageLink(item) {
      return getEntityRoute(item, { defaultValue: {} });
    },
    loadMore() {
      if (this.hasMoreData) {
        const type = this.dataType || this.titleEvent;
        this.$root.$emit('loadMore', type);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-button-next,
.swiper-button-prev {
  background-image: none;
}

.full-width {
  max-width: none !important;
}

.slider-width {
  @media (max-width: 640px) {
    max-width: 75% !important;
  }
}
</style>
