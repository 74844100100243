<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="14"
    height="8"
    viewBox="0 0 14 8"
  >
    <defs><path id="2kzoa" d="M1279 976l6.02 6 5.98-5.96" /></defs>
    <g>
      <g transform="translate(-1278 -975)">
        <use
          fill="#fff"
          fill-opacity="0"
          stroke="#363636"
          stroke-miterlimit="50"
          stroke-width="1.7"
          xlink:href="#2kzoa"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheArrowDownIcon',
};
</script>
