<template>
  <div class="swiper-slide">
    <AppRouteLink class="main-carousel" :route-to="routeTo">
      <div class="main-carousel__img" :style="`background-image: url(${media})`" />

      <div class="main-carousel__text-wrapper">
        <p v-show="typeTitle" class="main-carousel__title">{{ typeTitle }}</p>
        <h2 class="main-carousel__heading text-uppercase">{{ slide.entity.title }}</h2>
        <span v-show="slide.short_description" class="main-carousel__small small-text">{{
          slide.short_description
        }}</span>
      </div>
    </AppRouteLink>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';

import AppRouteLink from '@/components/partials/AppRouteLink';

export default {
  components: { AppRouteLink },
  props: {
    slide: {
      type: Object,
      default: () => ({
        entity: {
          __typename: '',
          id: null,
          destination: {
            id: null,
          },
          media: null,
          title: '',
        },
        short_description: '',
      }),
    },
  },
  computed: {
    media() {
      return new ImageHandler().getFirstImgFromMedia(this.slide.entity);
    },
    routeTo() {
      return getEntityRoute(this.slide.entity);
    },
    typeTitle() {
      const type = this.slide.entity.__typename;

      return !type
        ? null
        : type
            .replace('Type', '')
            .replace(/([A-Z])/g, ' $1')
            .trim();
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  max-width: none;
}

.main-carousel__img {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(#000, 0.4);

    @media (min-width: 768px) {
      display: none;
    }
  }
}
</style>
