type AppConfigValue = string | number | boolean | null | undefined;
type AppConfigKey =
  | 'env'
  | 'baseUrl'
  | 'marketingSiteBaseUrl'
  | 'googleAnalyticsId'
  | 'mapboxToken'
  | 'swAutoCheckUpdateTime'
  | 'redirectUsersWithLapsedMembershipTo';

export type AppConfigType = {
  [key in AppConfigKey]: AppConfigValue;
};

class AppConfig {
  private static instance: AppConfig;
  readonly #config: AppConfigType;

  private constructor() {
    this.#config = {
      env: process.env.NODE_ENV,
      baseUrl: process.env.VUE_APP_BASE_URL,
      marketingSiteBaseUrl: process.env.VUE_APP_MARKETING_SITE_BASE_URL,
      googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
      mapboxToken: process.env.VUE_APP_MAPBOX_TOKEN,
      swAutoCheckUpdateTime: process.env.VUE_APP_SW_AUTO_CHECK_UPDATE_TIME_SEC,
      redirectUsersWithLapsedMembershipTo: 'payment-details',
    };
  }

  public static getInstance(): AppConfig {
    if (!AppConfig.instance) {
      AppConfig.instance = new AppConfig();
    }
    return AppConfig.instance;
  }

  public get(key: AppConfigKey, defaultValue: AppConfigValue = undefined): AppConfigValue {
    return this.#config[key] || defaultValue;
  }

  public getAsString(key: AppConfigKey, defaultValue: AppConfigValue = undefined): string | undefined {
    const value = this.get(key, defaultValue);

    return value !== undefined ? String(value) : value;
  }

  public all(): AppConfigType {
    return this.#config;
  }
}

export default AppConfig.getInstance();
