<template>
  <div class="app-city-select-wrapper">
    <div class="app-city-select">
      <label class="app-city-select__label" for="app-city-select">What's happening in</label>

      <Multiselect
        id="app-city-select"
        :allow-empty="false"
        label="title"
        :options="cities"
        :placeholder="currentCity.title"
        :preselect-first="false"
        :preserve-search="true"
        :show-labels="false"
        track-by="id"
        :value="currentCity"
        @select="handleSelect"
      >
        <template #caret="{ toggle }">
          <button class="app-city-select-caret" type="button" @mousedown.prevent.stop="toggle">
            <TheArrowDownIcon class="app-city-select-caret__icon" />
          </button>
        </template>
        <template #noResult>
          <span class="app-city-select-no-results">No cities found.</span>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import TheArrowDownIcon from '@/components/icons/TheArrowDownIcon.vue';

export default {
  name: 'AppCitySelectList',
  components: {
    TheArrowDownIcon,
    Multiselect,
  },
  props: {
    cities: {
      type: Array,
      required: true,
    },
    currentCity: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleSelect(city) {
      this.$emit('selected', city);
    },
  },
};
</script>
