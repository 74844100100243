<template>
  <AppRouteLink class="item" :route-to="routeTo">
    <div class="item__icon">
      <component :is="icon" />
    </div>
    <div class="item__text">{{ item.search_result_title }}</div>
  </AppRouteLink>
</template>

<script>
import { EntityTypes, getEntityRoute } from '@/helpers/entityType';

import AppRouteLink from '@/components/partials/AppRouteLink';

export default {
  name: 'AppSearchAutoSuggestListItem',
  components: {
    AppRouteLink,
    TheArchMonumentIcon: () => import('@/components/icons/TheArchMonumentIcon.vue'),
    TheGeoMarkerIcon: () => import('@/components/icons/TheGeoMarkerIcon.vue'),
    TheHistoricalSiteIcon: () => import('@/components/icons/TheHistoricalSiteIcon.vue'),
    TheMsgEnvelopeOpenedIcon: () => import('@/components/icons/TheMsgEnvelopeOpenedIcon.vue'),
    TheNewspaperIcon: () => import('@/components/icons/TheNewspaperIcon.vue'),
    ThePaintPaletteIcon: () => import('@/components/icons/ThePaintPaletteIcon.vue'),
    TheStandImageIcon: () => import('@/components/icons/TheStandImageIcon.vue'),
    TheTripBagIcon: () => import('@/components/icons/TheTripBagIcon.vue'),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.item.__typename) {
        case EntityTypes.art_fair:
          return 'ThePaintPaletteIcon';
        case EntityTypes.art_space:
          return 'TheArchMonumentIcon';
        case EntityTypes.art_trip:
          return 'TheTripBagIcon';
        case EntityTypes.destination:
          return 'TheGeoMarkerIcon';
        case EntityTypes.event:
          return 'TheMsgEnvelopeOpenedIcon';
        case EntityTypes.exhibition:
          return 'TheStandImageIcon';
        case EntityTypes.historical_site:
          return 'TheHistoricalSiteIcon';
        case EntityTypes.story:
        case EntityTypes.member_site_page:
          return 'TheNewspaperIcon';
        default:
          return 'TheMsgEnvelopeOpenedIcon';
      }
    },
    routeTo() {
      return getEntityRoute(this.item);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  color: inherit;

  &:hover {
    color: #888 !important;
  }

  &__icon {
    margin-right: 15px;

    svg {
      width: 30px;
      height: auto;
    }
  }

  &__text {
    margin-top: 5px;
  }
}
</style>
