<template>
  <div
    v-if="showState"
    class="d-flex align-items-center justify-content-center wrapper-modal-permission"
    @click.self="onClose"
  >
    <div class="content">
      Your location tracking is off. To enjoy all the benefits of the Cultivist app, please allow it to access your
      location!
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showState: this.show,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        if (show === false) {
          window.sessionStorage.removeItem('permissionsModalAlreadyShown');
          this.showState = false;
          return;
        }

        if (window.sessionStorage.getItem('permissionsModalAlreadyShown')) {
          this.showState = false;
          return;
        }

        this.showState = !!show;
        if (this.showState) {
          window.sessionStorage.setItem('permissionsModalAlreadyShown', 'true');

          setTimeout(() => {
            this.showState = false;
          }, 5000);
        }
      },
    },
  },
  methods: {
    onClose() {
      window.sessionStorage.setItem('permissionsModalAlreadyShown', 'true');
      this.showState = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-modal-permission {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5555;
  width: 100%;
  height: 100vh;
  background-color: rgba(#000, 0.5);

  .content {
    background-color: #fff;
    max-width: 840px;
    width: 100%;
    padding: 40px;
    color: #363636;
    font-size: 22px;
  }
}
</style>
