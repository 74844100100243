export default {
  academy: 'academies',
  artFair: 'artFairs',
  artSpace: 'artSpaces',
  artTrip: 'artTrips',
  event: 'events',
  exhibition: 'exhibitions',
  exhibitionFromGallery: 'exhibitionsFromGallery',
  exhibitionFromMuseum: 'exhibitionsFromMuseum',
  historicalSite: 'historicalSites',
  miniCultivist: 'miniCultivists',
  story: 'stories',
};
