<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 20.5H27.5M14 10C14.3978 10 14.7794 10.158 15.0607 10.4393C15.342 10.7206 15.5 11.1022 15.5 11.5V14.5C15.5 14.8978 15.342 15.2794 15.0607 15.5607C14.7794 15.842 14.3978 16 14 16C13.6022 16 13.2206 15.842 12.9393 15.5607C12.658 15.2794 12.5 14.8978 12.5 14.5V11.5C12.5 11.1022 12.658 10.7206 12.9393 10.4393C13.2206 10.158 13.6022 10 14 10V10ZM32 13H15.5H32ZM12.5 13H8H12.5ZM14 25C14.3978 25 14.7794 25.158 15.0607 25.4393C15.342 25.7206 15.5 26.1022 15.5 26.5V29.5C15.5 29.8978 15.342 30.2794 15.0607 30.5607C14.7794 30.842 14.3978 31 14 31C13.6022 31 13.2206 30.842 12.9393 30.5607C12.658 30.2794 12.5 29.8978 12.5 29.5V26.5C12.5 26.1022 12.658 25.7206 12.9393 25.4393C13.2206 25.158 13.6022 25 14 25ZM32 28H15.5H32ZM12.5 28H8H12.5ZM26 17.5C26.3978 17.5 26.7794 17.658 27.0607 17.9393C27.342 18.2206 27.5 18.6022 27.5 19V22C27.5 22.3978 27.342 22.7794 27.0607 23.0607C26.7794 23.342 26.3978 23.5 26 23.5C25.6022 23.5 25.2206 23.342 24.9393 23.0607C24.658 22.7794 24.5 22.3978 24.5 22V19C24.5 18.6022 24.658 18.2206 24.9393 17.9393C25.2206 17.658 25.6022 17.5 26 17.5V17.5ZM24.5 20.5H8H24.5Z"
      stroke="#363636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheFilterIcon',
};
</script>
