class ImageHandler {
  public getFirstImgFromMedia(item: any): string {
    let media = '';

    if (item && item.media) {
      if (item.media.url) {
        media = item.media.url;
      } else if (item.media[0] && item.media[0].url) {
        media = item.media[0].url;
      }
    } else if (item && item.titleMedia) {
      media = item.titleMedia;
    }

    return media;
  }

  public getFirstImgFromEvent(item: any): string {
    let media = '';

    if (
      item.events &&
      item.events.length &&
      item.events[0].media &&
      item.events[0].media.length &&
      item.events[0].media[0].url
    ) {
      media = item.events[0].media[0].url;
    }

    return media;
  }
}

export default ImageHandler;
