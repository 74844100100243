<template>
  <div v-if="slides.length" class="main-carousel-box-wrap">
    <div class="container main-carousel-box">
      <div class="row">
        <div class="col-12">
          <swiper ref="mySwiper" class="main-carousel-wrap" :options="swiperOptions">
            <AppMainPageSliderItem v-for="slide in slides" :key="slide.id" :slide="slide" />
          </swiper>
        </div>
      </div>
    </div>

    <div
      v-if="slides.length > 1"
      slot="button-prev"
      class="swiper-button-prev main-carousel__nav-btn main-carousel__nav-btn-prev"
    />
    <div
      v-if="slides.length > 1"
      slot="button-next"
      class="swiper-button-next main-carousel__nav-btn main-carousel__nav-btn-next"
    />
  </div>
</template>

<script>
import AppMainPageSliderItem from '@/components/partials/AppMainPageSliderItem';

export default {
  name: 'AppMainPageSlider',
  components: { AppMainPageSliderItem },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    swiperOptions() {
      return {
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 30,
        initialSlide: 0,
        loop: this.slides.length > 1,
        autoHeight: true,
        navigation: {
          nextEl: '.main-carousel__nav-btn-next',
          prevEl: '.main-carousel__nav-btn-prev',
        },
        breakpoints: {
          767: {
            centeredSlides: false,
            spaceBetween: 0,
            slidesPerView: 1,
            initialSlide: 0,
          },
        },
      };
    },
  },
};
</script>
