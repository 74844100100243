<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27 16H7" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M33 10H7" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M33 22H7" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M27 28H7" stroke="#363636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'TheMenuHamburgerIcon',
};
</script>
