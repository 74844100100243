<template>
  <div class="entity-card-label" :style="styles">
    <span class="entity-card-label__icon">
      <slot name="icon"></slot>
    </span>
    <span class="entity-card-label__text">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'EntityCardLabel',
  props: {
    label: {
      type: String,
      required: true,
    },
    hAlign: {
      type: String,
      default: 'left',
      validator: (value) => value === 'left' || value === 'right',
    },
    vAlign: {
      type: String,
      default: 'bottom',
      validator: (value) => value === 'bottom' || value === 'top',
    },
  },
  computed: {
    styles() {
      return {
        '--ec-label-pos-left': this.hAlign === 'left' ? '-7px' : 'auto',
        '--ec-label-pos-right': this.hAlign === 'right' ? '-7px' : 'auto',
        '--ec-label-pos-bottom': this.vAlign === 'bottom' ? '15px' : 'auto',
        '--ec-label-pos-top': this.vAlign === 'top' ? '15px' : 'auto',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-card-label {
  position: absolute;
  bottom: var(--ec-label-pos-bottom);
  left: var(--ec-label-pos-left);
  right: var(--ec-label-pos-right);
  top: var(--ec-label-pos-top);

  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 8px 16px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 4px hsla(0, 0%, 68.6%, 0.5);
  color: #363636;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
}
</style>
