interface SimpleCoordinates {
  latitude: number | null;
  longitude: number | null;
}

const isGeolocationSupport = () => {
  return 'geolocation' in window.navigator;
};

const saveCurrentGeoPosition = (coordinates: GeolocationCoordinates) => {
  localStorage.setItem('latitude', coordinates.latitude.toString());
  localStorage.setItem('longitude', coordinates.longitude.toString());
};

const removeCurrentGeoPosition = () => {
  localStorage.removeItem('latitude');
  localStorage.removeItem('longitude');
};

export function navigatorCurrentPosition(onSuccess = () => {}, onError = () => {}) {
  if (!isGeolocationSupport()) {
    return;
  }

  navigator.geolocation.getCurrentPosition(
    (position) => {
      saveCurrentGeoPosition(position.coords);
      onSuccess();
    },
    (err) => {
      if (err.code === err.PERMISSION_DENIED) {
        removeCurrentGeoPosition();
      }
      onError();
    },
    { timeout: 15000 }
  );
}

export function getCurrentGeoPosition(): SimpleCoordinates {
  const latitude: string | null = localStorage.getItem('latitude');
  const longitude: string | null = localStorage.getItem('longitude');

  return {
    latitude: latitude ? parseFloat(latitude) : null,
    longitude: longitude ? parseFloat(longitude) : null,
  };
}

export function isCurrentGeoPositionExist(): boolean {
  const coordinates = getCurrentGeoPosition();

  return (
    coordinates.latitude !== null &&
    coordinates.longitude !== null &&
    !isNaN(coordinates.latitude) &&
    !isNaN(coordinates.longitude)
  );
}

export function validateAndGetCoordinates(data: any): SimpleCoordinates | false {
  const latitude = parseFloat(data.artSpace && !isNaN(data.artSpace.latitude) ? data.artSpace.latitude : data.latitude);
  const longitude = parseFloat(
    data.artSpace && !isNaN(data.artSpace.longitude) ? data.artSpace.longitude : data.longitude
  );

  return !!latitude && !!longitude ? { latitude, longitude } : false;
}

// Not supported on Safari 15 and below
export function handleGeolocationPermissions(onGranted = () => {}, onDenied = () => {}) {
  if (!isGeolocationSupport()) {
    return;
  }
  window.navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
    if (permissionStatus.state === 'granted') {
      onGranted();
    } else if (permissionStatus.state === 'denied') {
      onDenied();
    }

    permissionStatus.onchange = () => {
      if (permissionStatus.state === 'granted') {
        onGranted();
      } else if (permissionStatus.state === 'denied') {
        onDenied();
      }
    };
  });
}
