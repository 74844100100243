export default [
  {
    name: 'Book',
    items: [
      {
        name: 'Events',
        route: {
          path: '/events',
        },
      },
      {
        name: 'Art Trips',
        route: {
          path: '/art-trips',
        },
      },
    ],
  },
  {
    name: 'Explore',
    items: [
      {
        name: 'Destinations',
        route: {
          path: '/destinations',
        },
      },
      {
        name: 'Museums & Galleries',
        route: {
          path: '/art-spaces',
        },
      },
      {
        name: 'Art Fairs',
        route: {
          path: '/art-fairs',
        },
      },
      {
        name: 'Exhibitions',
        route: {
          path: '/exhibitions',
        },
      },
    ],
  },
  {
    name: 'Learn',
    items: [
      {
        name: 'Mini Cultivist',
        route: {
          path: '/minicultivist',
        },
      },
      {
        name: 'Academy',
        route: {
          path: '/academy',
        },
      },
      {
        name: 'Stories',
        route: {
          path: '/stories',
        },
      },
    ],
  },
];
