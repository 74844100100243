








































import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { navigatorCurrentPosition } from '@/helpers/GeolocationHelper';
import handleResizeWithDebounce from '@/helpers/windowResizeHandlerWithDebounce';

import meQuery from '@/graphql/me/Me.query.gql';

import AppLoaderScreen from '@/components/AppLoaderScreen.vue';
import AppHeader from '@/components/partials/AppHeader.vue';
import AppMainMenu from '@/components/AppMainMenu.vue';
import MsgInbox from '@/components/partials/profile/MsgInbox.vue';
import PermissionsModal from '@/components/partials/PermissionsModal.vue';
import InboxMsgIcon from '@/components/icons/InboxMsgIcon.vue';
import TheSearchIcon from '@/components/icons/TheSearchIcon.vue';
import RenewYourMembershipModal from '@/components/modals/RenewYourMembershipModal.vue';

let debounceResize: any = handleResizeWithDebounce();

@Component({
  components: {
    AppLoaderScreen,
    InboxMsgIcon,
    AppHeader,
    AppMainMenu,
    MsgInbox,
    PermissionsModal,
    RenewYourMembershipModal,
    TheSearchIcon,
  },
  metaInfo: {
    titleTemplate: '%s - The Cultivist Members',
  },
  computed: {
    ...mapState([
      'isAuth',
      'isMainMenuShown',
      'isMobileScreen',
      'isMsgInboxShown',
      'isPermissionsModalShown',
      'showLoaderScreen',
      'isRenewMembershipModalShown',
      'unreadAppMessagesCount',
      'user',
    ]),
    ...mapGetters(['isUserMembershipLapsed']),
  },
  apollo: {
    me: {
      query: meQuery,
      skip() {
        return !this.isAuth;
      },
      variables() {
        return {
          withSubscription: true,
        };
      },
      result({ data }: any) {
        if (data?.me) {
          this.$store.dispatch('setUser', data.me);

          const unreadMessagesCount = data.me.unreadAppMessagesCount || 0;
          this.$store.dispatch('setUnreadAppMessagesCount', unreadMessagesCount);

          if (data.me.membership) {
            this.$store.dispatch('setUserMembership', { ...data.me.membership });
          }
        }
      },
    },
  },
})
export default class App extends Vue {
  isMainMenuShown: boolean;
  isMobileScreen: boolean;
  isMsgInboxShown: boolean;
  isUserMembershipLapsed: boolean;

  scrolled = false;
  firstPath = '';
  hasFixedTabs = false;
  isMobileHeader = false;

  pwaUpdateExists = false;
  pwaRefreshing = false;
  pwaRegistration: ServiceWorkerRegistration | null = null;

  @Watch('$route')
  onRouteChange() {
    this.hideMainMenu();
    this.isMobileHeader = !!document.querySelector('.with-mobile-header');
  }

  @Watch('isMobileScreen')
  watchIsMobileScreen(isMobileScreen: boolean) {
    if (isMobileScreen && this.isMainMenuShown) {
      this.$store.dispatch('disableScroll');
    } else {
      this.$store.dispatch('enableScroll');
    }
  }

  @Watch('isUserMembershipLapsed', { immediate: true })
  watchIsUserMembershipLapsed(isUserMembershipLapsed: boolean) {
    this.$store.dispatch('showOrHideRenewMembershipModalShown', isUserMembershipLapsed);
  }

  created() {
    this.$store.dispatch('checkIsAppleDevice');
    this.showOrHideMainSearchModal(false);
    this.hideMsgInbox();
    this.hideMainMenu();
    this.$store.dispatch('toggleAppDataFilter', false);

    // Listen for swUpdated event and display refresh button as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!this.pwaRefreshing) {
          this.pwaRefreshing = true;
          window.location.reload();
        }
      });
    }

    navigatorCurrentPosition(
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', false);
      },
      () => {
        this.$store.dispatch('showOrHidePermissionsModal', true);
      }
    );

    window.addEventListener('scroll', this.handleScroll);

    this.firstPath = this.$route.path;

    this.handleReadMoreBtn();

    this.determineIsMobileScreen();
    window.addEventListener('resize', this.handleResize);
  }

  updated() {
    if (this.$route.path !== this.firstPath) {
      this.firstPath = this.$route.path;
    }
    this.handleReadMoreBtn();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
    debounceResize = null;
    this.$store.dispatch('showLoaderScreen', false);
  }

  determineIsMobileScreen() {
    this.$store.dispatch('determineIsMobileScreen', window.innerWidth);
  }

  handleCloseRenewYourMembershipModal() {
    this.$store.dispatch('showOrHideRenewMembershipModalShown', false);
  }

  handleResize() {
    if (debounceResize !== null) {
      debounceResize(() => {
        this.determineIsMobileScreen();
        this.handleReadMoreBtn();
      });
    }
  }

  handleScroll() {
    this.scrolled = window.scrollY > 0;
  }

  handleReadMoreBtn() {
    if (this.$route.name === 'insider-tip') {
      return;
    }

    const readMoreButtons = document.querySelectorAll('.read-more');

    for (let i = 0; i < readMoreButtons.length; i++) {
      const btn = readMoreButtons[i] as HTMLElement;

      const desc = btn.previousElementSibling as HTMLElement;
      if (!desc) {
        return;
      }

      const descInner = desc.querySelector('.desc__inner') as HTMLElement;
      if (!descInner) {
        return;
      }

      if (descInner.offsetHeight > desc.offsetHeight) {
        btn.style.display = 'block';
        desc.classList.remove('without-shadow');
      } else {
        btn.style.display = 'none';
        desc.classList.add('without-shadow');
      }
    }
  }

  showMsgInbox() {
    this.$store.dispatch('showMsgInbox', true);
  }

  hideMsgInbox() {
    this.$store.dispatch('showMsgInbox', false);
  }

  showRefreshUI(e: any) {
    // Display a button inviting the user to refresh/reload the app due
    // to an app update being available.
    // The new service worker is installed, but not yet active.
    // Store the ServiceWorkerRegistration instance for later use.
    this.pwaRegistration = e.detail;
    this.pwaUpdateExists = true;

    setTimeout(() => {
      this.refreshPwa();
    }, 1000);
  }

  refreshPwa() {
    // Handle a user tap on the update app button.
    this.pwaUpdateExists = false;
    // Protect against missing registration.waiting.
    if (this.pwaRegistration && this.pwaRegistration.waiting) {
      this.pwaRegistration.waiting.postMessage({ type: 'skipWaiting' });
    }
  }

  hideMainMenu() {
    this.$store.dispatch('showMainMenu', false);
  }

  showOrHideMainSearchModal(value: boolean) {
    if (!this.isUserMembershipLapsed) {
      this.hideMainMenu();
    }
    this.$store.dispatch('showMainSearchModal', value);
  }

  logout() {
    this.hideMainMenu();
    this.$store.dispatch('logout').then(() => {
      this.$router.push({ name: 'login' });
    });
  }
}
