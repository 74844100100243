

































































import { Component, Vue, Watch } from 'vue-property-decorator';
import InboxMessage from '@/interfaces/InboxMessage';

import inAppMessagesQuery from '@/graphql/inAppMessage/InAppMessages.query.gql';
import inAppMessagesCountQuery from '@/graphql/inAppMessage/InAppMessagesCount.query.gql';
import updateInboxMessageReadStatusMutation from '@/graphql/inAppMessage/updateInboxMessageReadStatus.mutation.gql';
import deleteInboxMessageMutation from '@/graphql/inAppMessage/deleteInboxMessage.mutation.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import MsgInboxActiveMessage from '@/components/partials/profile/MsgInboxActiveMessage.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import TheTrashIcon from '@/components/icons/TheTrashIcon.vue';
import EyeClosedIcon from '@/components/icons/EyeClosedIcon.vue';
import CheckedMarkIcon from '@/components/icons/CheckedMarkIcon.vue';

@Component({
  name: 'MsgInbox',
  components: {
    CheckedMarkIcon,
    EyeClosedIcon,
    TheTrashIcon,
    TheArrowHorizIcon,
    BackHeader,
    MsgInboxActiveMessage,
  },
  apollo: {
    me: {
      query: inAppMessagesCountQuery,
      fetchPolicy: `no-cache`,
      result({ data }: any) {
        if (data?.me) {
          const unreadMessagesCount = data.me.unreadAppMessagesCount || 0;
          this.$store.dispatch('setUnreadAppMessagesCount', unreadMessagesCount);
        }
      },
    },
    InAppMessages: {
      query: inAppMessagesQuery,
      fetchPolicy: `no-cache`,
      variables() {
        return {
          limit: this.limit,
          nextCursor: this.nextCursor,
        };
      },
      result(result: any) {
        this.startLoading = false;

        if (result.data && result.data.InAppMessages) {
          this.hasMoreMessages = result.data.InAppMessages.hasMorePages;
          this.inAppMessagesList.push(...result.data.InAppMessages.data);

          this.busy = false;

          if (!this.inAppMessagesList.length) {
            return;
          }
          this.$nextTick(() => {
            const loadState = this.$refs.infiniteLoad.stateChanger;

            if (!this.hasMoreMessages) {
              loadState.complete();
            } else {
              loadState.loaded();
            }
          });
        }
      },
    },
  },
})
export default class MsgInbox extends Vue {
  public startLoading = true;
  public busy = false;
  public activeMessage: InboxMessage | null = null;

  public InAppMessages: any;
  public inAppMessagesList: any[] = [];
  public limit = 6;
  public nextCursor: string | null = null;
  public hasMoreMessages = true;

  get isMobileScreenExclude768() {
    return this.$store.state.isMobileScreenExclude768;
  }

  @Watch('$route')
  watchChangeRoute() {
    if (this.isMobileScreenExclude768) {
      this.$root.$emit('close-aside');
    }
    this.close();
  }

  created() {
    if (window.innerWidth < 768) {
      this.$store.dispatch('disableScroll');
    }
  }

  loadMore(state: any) {
    if (this.inAppMessagesList.length && this.hasMoreMessages) {
      this.busy = true;
      this.nextCursor = this.InAppMessages.nextCursor;
      return;
    }
    state.complete();
  }

  formatDate(date: string) {
    return this.$moment(date).fromNow();
  }

  close() {
    this.$emit('close');

    if (window.innerWidth < 768) {
      this.$store.dispatch('enableScroll');
    }
  }

  showMessage(message: InboxMessage) {
    this.activeMessage = message;

    if (!message.is_read) {
      this.$apollo
        .mutate({
          mutation: updateInboxMessageReadStatusMutation,
          variables: {
            id: message.id,
            isRead: true,
          },
        })
        .then((result) => {
          message.is_read = result.data.updateInboxMessageReadStatus.is_read;
          this.$apollo.queries.me.refetch();
        });
    }
  }

  closeMessage() {
    this.activeMessage = null;
  }

  handleUpdateReadStatus(message: any) {
    this.$apollo
      .mutate({
        mutation: updateInboxMessageReadStatusMutation,
        variables: {
          id: message.id,
          isRead: !message.is_read,
        },
      })
      .then((result) => {
        const messageIndex = this.inAppMessagesList.findIndex((m) => m.id === message.id);
        this.inAppMessagesList.splice(messageIndex, 1, {
          ...message,
          is_read: result.data.updateInboxMessageReadStatus.is_read,
        });
        this.$apollo.queries.me.refetch();
      });
  }

  handleDeleteMessage(message: any) {
    this.$apollo
      .mutate({
        mutation: deleteInboxMessageMutation,
        variables: {
          id: message.id,
        },
      })
      .then((result) => {
        if (result.data.deleteInboxMessage) {
          const messageIndex = this.inAppMessagesList.findIndex((m) => m.id === message.id);
          this.inAppMessagesList.splice(messageIndex, 1);
          this.$apollo.queries.me.refetch();
        }
      });
  }
}
