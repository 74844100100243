<template>
  <div class="data-item" :class="{ 'col-lg-4 col-sm-6 col-12 col-md-6': displayAsRowCol }">
    <AppRouteLink class="data-item__link" :route-to="routeTo">
      <div class="data-item__img-wrapper">
        <div class="data-item__img" :style="`background-image: url(${media})`" />

        <EntityCardLabel v-if="entityCardLabel" :label="entityCardLabel" />
        <StaffPickLabel v-if="event.staff_pick" :v-align="entityCardLabelForStaffPickHorizAlign" />
      </div>

      <div v-if="event.title" class="category-head__title" style="font-size: 22px; line-height: 23px">
        {{ event.title }}
      </div>

      <slot name="body" :dates="dates" :publish-date="publishDate">
        <div class="d-flex">
          <div v-if="event.organizerTitle" class="small-grey">{{ event.organizerTitle }} |</div>
          <div v-if="event.type" class="small-grey">{{ event.type }}</div>
        </div>

        <div v-if="event.artSpace" class="small-grey mb-2">{{ event.artSpace.title }}</div>

        <DataItemDates :dates="dates" />
        <div v-if="publishDate" class="small-grey">{{ publishDate }}</div>

        <div v-if="event.__typename !== 'StoryType' && event.destination" class="d-flex">
          <div class="small-grey" v-if="event.destination.title">{{ event.destination.title }},</div>
          <div class="small-grey ml-1" v-if="event.destination.country">{{ event.destination.country }}</div>
        </div>
      </slot>
    </AppRouteLink>
  </div>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import ImageHandler from '@/helpers/ImageHandler';
import { getEntityCardLabel, getEntityCardLabelForStaffPickHorizAlign } from '@/helpers/entityCardLabel';
import { formatDate } from '@/helpers/dateTimeHelper';

import DataItemDates from '@/components/partials/DataItemDates';
import AppRouteLink from '@/components/partials/AppRouteLink';
import EntityCardLabel from '@/components/partials/EntityCardLabel.vue';
import StaffPickLabel from '@/components/partials/StaffPickLabel.vue';

export default {
  name: 'AppEventSliderItem',
  components: { StaffPickLabel, EntityCardLabel, AppRouteLink, DataItemDates },
  props: {
    event: {
      type: Object,
      required: true,
    },
    displayAsRowCol: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    dates() {
      if (this.event.date && this.event.date.length) {
        return this.event.date;
      }
      return [
        {
          start_date: this.event.start_date || null,
          end_date: this.event.end_date || null,
        },
      ];
    },
    entityCardLabel() {
      return getEntityCardLabel(this.event);
    },
    entityCardLabelForStaffPickHorizAlign() {
      return getEntityCardLabelForStaffPickHorizAlign(this.event);
    },
    media() {
      return new ImageHandler().getFirstImgFromMedia(this.event);
    },
    publishDate() {
      return formatDate(this.event.startPublishingAt);
    },
    routeTo() {
      return getEntityRoute(this.event);
    },
  },
};
</script>

<style lang="scss" scoped>
.category-head__title {
  margin-bottom: 10px;
}
.data-item {
  $item: &;

  &__img {
    &-wrapper {
      position: relative;
    }
    transition: all 0.3s linear;
  }

  &__link {
    &:hover #{$item}__img {
      transform: scale(0.95);
    }
  }
}
</style>
