export function getEntityCardLabel(entity: any) {
  if (!entity || typeof entity !== 'object') {
    return '';
  }
  if (entity.partner_museum) {
    return 'Partner museum';
  }
  if (entity.is_upcoming) {
    return 'Opening soon';
  }
  return '';
}

export function getEntityCardLabelForStaffPickHorizAlign(entity: any) {
  return getEntityCardLabel(entity) ? 'top' : 'bottom';
}
